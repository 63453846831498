import { IPagination } from 'interfaces/pagination.interface';

export const DEFAULT_SEASONS_PAGINATION: IPagination = {
  limit: 100,
  page: 1,
};

export const DEFAULT_CATEGORIES_PAGINATION: IPagination = {
  limit: 20,
  page: 1,
};

export const DEFAULT_TRANSACTIONS_PAGINATION: IPagination = {
  limit: 20,
  page: 1,
};
