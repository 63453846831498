export enum TransactionOwnersType {
  ATHLETE = 'athlete',
}

export enum TransactionActionsType {
  COMMENTED = 'commented',
  LIKED = 'liked',
  CREATED = 'created',
  PARTICIPATED = 'participated',
  WIN_PREDICTION = 'WIN_PREDICTION',
  LOSS_PREDICTION = 'LOSS_PREDICTION',
}

export interface IReputationPopupData {
  type: ReputationPointsCategories;
  subTypeId?: Maybe<number>;
  username: string;
}

export enum ReputationPointsCategories {
  TOTAL_POINTS = 'total_points',
  PLAYER_POINTS = 'player_points',
  PARTICIPATION_POINTS = 'participation_points',
  PREDICTION_POINTS = 'prediction_points',
}

export interface IDaysStreak {
  id: number;
  max: number;
  current: number;
  createdAt: Date;
  updatedAt: Date;
}
