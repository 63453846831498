import { inject, injectable } from 'inversify';
import { action, autorun, makeObservable, observable } from 'mobx';

import { SeasonsStore } from 'stores/seasons/seasons.store';

import { TYPES } from 'configs/di-types.config';

@injectable()
export class AchievementsStore {
  private readonly seasonsStore: SeasonsStore;

  public seasonId: Maybe<string>;

  constructor(
    @inject<SeasonsStore>(TYPES.SeasonsStore)
    seasonsStore: SeasonsStore,
  ) {
    this.seasonsStore = seasonsStore;

    this.seasonId = null;

    makeObservable(this, {
      seasonId: observable,

      setSeasonId: action.bound,
    });

    autorun(() => this.setSeasonId(this.seasonsStore.currentReputationSeasonOption?.value ?? null));
  }

  public setSeasonId(seasonId: Maybe<string>) {
    this.seasonId = seasonId;
  }
}
