import { ITransactionCategoryResponse } from 'services/reputation-points/interfaces/transaction-category-response.interface';

import { bucketAdapter } from 'stores/buckets/adapters/bucket-adapter.util';
import { ITransactionCategory } from 'stores/transaction-buckets/interfaces/transaction-category.interface';

export function transactionCategoryAdapter(
  response: ITransactionCategoryResponse,
): ITransactionCategory {
  const {
    bucket,
    total_points: totalPoints,
    player_points: playerPoints,
    participation_points: participationPoints,
    prediction_points: predictionPoints,
    total_points_rank: totalPointsRank,
    player_points_rank: playerPointsRank,
    participation_points_rank: participationPointsRank,
    prediction_points_rank: predictionPointsRank,
  } = response;

  return {
    bucket: bucketAdapter(bucket),
    totalPoints,
    playerPoints,
    participationPoints,
    predictionPoints,
    totalPointsRank,
    playerPointsRank,
    participationPointsRank,
    predictionPointsRank,
  };
}
