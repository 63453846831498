import { useCallback, useMemo } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import { observer } from 'mobx-react-lite';

import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';
import { getPath } from 'helpers/get-path.util';
import * as paths from 'routes/paths.constants';

import { useResponsive } from 'hooks/use-responsive';

import { BarAction } from 'components/bars/bar-action.enum';
import { BarActionClick, BarActionType } from 'components/bars/bar-action.type';
import { ITab, Tabs } from 'components/ui/tabs/tabs.component';

enum ProfileTabs {
  Feed = 'Feed',
  Stats = 'Stats',
  Achievements = 'Achievements',
  Profile = 'Profile',
}

const PROFILE_TABS: ITab<BarActionClick<ProfileTabs>>[] = [
  {
    id: ProfileTabs.Feed,
    title: ProfileTabs.Feed,
    action: {
      type: BarAction.Click,
      payload: ProfileTabs.Feed,
    },
  },
  {
    id: ProfileTabs.Stats,
    title: ProfileTabs.Stats,
    action: {
      type: BarAction.Click,
      payload: ProfileTabs.Stats,
    },
  },
  {
    id: ProfileTabs.Achievements,
    title: ProfileTabs.Achievements,
    action: {
      type: BarAction.Click,
      payload: ProfileTabs.Achievements,
    },
  },
  {
    id: ProfileTabs.Profile,
    title: ProfileTabs.Profile,
    action: {
      type: BarAction.Click,
      payload: ProfileTabs.Profile,
    },
  },
];

export const UserPublicProfileTabsContainer = observer(() => {
  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);
  const location = useLocation();

  const activeTab = useMemo(() => {
    if (
      location.pathname.includes(paths.FEED_PART) ||
      location.pathname.includes(paths.PREDICTIONS_PART) ||
      location.pathname.includes(paths.BOOKMARKS)
    ) {
      return ProfileTabs.Feed;
    }

    if (location.pathname.includes(paths.STATS_PART)) {
      return ProfileTabs.Stats;
    }

    if (location.pathname.includes(paths.ACHIEVEMENTS)) {
      return ProfileTabs.Achievements;
    }

    return ProfileTabs.Profile;
  }, [location.pathname]);

  const navigate = useNavigate();

  const params = useParams<{
    [paths.USERNAME_PARAM]: string;
  }>();

  const { username } = params;

  const handleTabChange = useCallback(
    (action: BarActionType) => {
      if (action.type === BarAction.Click && action.payload && username) {
        const navigation = {
          [ProfileTabs.Feed]: paths.USER_PUBLIC_FEED,
          [ProfileTabs.Stats]: paths.USER_PUBLIC_STATS,
          [ProfileTabs.Achievements]: paths.USER_PUBLIC_ACHIEVEMENTS,
          [ProfileTabs.Profile]: paths.USER_PUBLIC_PROFILE,
        } as const;

        navigate(
          getPath(navigation[action.payload as ProfileTabs], {
            [paths.USERNAME_PARAM]: username,
          }),
        );
      }
    },
    [navigate, username],
  );

  if (!isDesktopPlus) {
    return null;
  }

  return <Tabs fullWidth activeTab={activeTab} tabs={PROFILE_TABS} onClick={handleTabChange} />;
});
