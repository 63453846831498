import { memo, useCallback, useMemo } from 'react';
import { HeaderClassParams, SortDirection } from 'ag-grid-community';
import cn from 'classnames';

import { ReputationPointsCategories } from 'stores/reputations-points/interfaces/reputation-points.interface';

import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';

import { useResponsive } from 'hooks/use-responsive';

import { IconFont, IconFontName, IconFontSize } from 'components/ui/icon-font/icon-font.component';

import styles from './leaderboard-header-cell.module.less';

interface ILeaderboardHeaderCellProps extends HeaderClassParams {
  iconName: IconFontName;
  title: string;
  sortParam: ReputationPointsCategories;
  setSort: (sort: SortDirection | undefined) => void;
  setSortParam: (param: ReputationPointsCategories) => void;
  getIsLoading: () => boolean;
}

export const LeaderboardHeaderCell = memo((props: ILeaderboardHeaderCellProps) => {
  const { iconName, title, column, setSort, setSortParam, sortParam, getIsLoading } = props;

  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);

  const sortDirection = useMemo(() => column?.getSort(), [column]);

  const handleClick = useCallback(() => {
    if (getIsLoading()) {
      return;
    }

    const newSortDirection = sortDirection === 'desc' ? 'asc' : 'desc';

    setSortParam(sortParam);
    setSort(newSortDirection);
  }, [setSort, sortDirection, setSortParam, sortParam, getIsLoading]);

  const classNames = useMemo(() => {
    return cn(styles.LeaderboardHeaderCell, {
      [styles.LeaderboardHeaderCell__highlighted]: !!sortDirection,
    });
  }, [sortDirection]);

  const sortClassNames = useMemo(() => {
    return cn({ [styles.LeaderboardHeaderCell__asc]: sortDirection === 'asc' });
  }, [sortDirection]);

  return (
    <button className={classNames} onClick={handleClick}>
      <IconFont size={IconFontSize.Small} name={iconName} />
      {isDesktopPlus && <span className={styles.LeaderboardHeaderCell__title}>{title}</span>}
      {sortDirection && (
        <div className={sortClassNames}>
          <IconFont size={IconFontSize.Small} name={IconFontName.MarkerExpanded} />
        </div>
      )}
    </button>
  );
});
