import { FC, useCallback, useMemo } from 'react';
import cn from 'classnames';

import { ReputationPointsCategories } from 'stores/reputations-points/interfaces/reputation-points.interface';

import { Rank, RankEnum } from 'components/rank/rank.component';
import { IconFont, IconFontName, IconFontSize } from 'components/ui/icon-font/icon-font.component';

import styles from './reputation-box-item.module.less';

interface ReputationBoxItemProps {
  title: string;
  content: number;
  type: ReputationPointsCategories;
  rank: number;
  onClick?: () => void;
}

export const ReputationBoxItem: FC<ReputationBoxItemProps> = (props: ReputationBoxItemProps) => {
  const { title, content, rank, onClick, type } = props;

  const handleBoxClick = useCallback(() => {
    onClick?.();
  }, [onClick]);

  const boxClassNames = useMemo(
    () =>
      cn(styles.StatsBox, {
        [styles['StatsBox--clickable']]: !!onClick,
      }),
    [onClick],
  );

  const titleClassNames = useMemo(
    () =>
      cn(styles.StatsBox__Title, {
        [styles['StatsBox__Title--total']]: type === ReputationPointsCategories.TOTAL_POINTS,
      }),
    [type],
  );

  const contentClassNames = useMemo(
    () =>
      cn(styles.StatsBox__Content, {
        [styles['StatsBox__Content--total']]: type === ReputationPointsCategories.TOTAL_POINTS,
        [styles['StatsBox__Content--prediction-low']]:
          type === ReputationPointsCategories.PREDICTION_POINTS && content < 0,
        [styles['StatsBox__Content--prediction-high']]:
          type === ReputationPointsCategories.PREDICTION_POINTS && content > 0,
      }),
    [type, content],
  );

  return (
    <div role="presentation" className={boxClassNames} onClick={handleBoxClick}>
      <div className={titleClassNames}>
        {type === ReputationPointsCategories.TOTAL_POINTS && (
          <IconFont name={IconFontName.StarFilled} size={IconFontSize.Small} />
        )}
        {type === ReputationPointsCategories.PLAYER_POINTS && (
          <IconFont name={IconFontName.SuperLikeFilled} size={IconFontSize.Small} />
        )}
        {type === ReputationPointsCategories.PARTICIPATION_POINTS && (
          <IconFont name={IconFontName.Like} size={IconFontSize.Small} />
        )}
        {type === ReputationPointsCategories.PREDICTION_POINTS && (
          <IconFont name={IconFontName.Chip} size={IconFontSize.Small} />
        )}
        <div>{title}</div>
      </div>
      <div className={contentClassNames}>{content}</div>
      <Rank type={RankEnum.RECTANGLE} rank={rank} />
    </div>
  );
};
