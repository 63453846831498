import { memo, useMemo } from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import cn from 'classnames';

import { IUserBalance } from 'stores/leaderboard/interfaces/leaderboard.interface';
import { ReputationPointsCategories } from 'stores/reputations-points/interfaces/reputation-points.interface';

import { RANK_FIELD_MAPPER } from 'components/leaderboard/leaderboard.config';
import { Loader, LoaderSizeEnum } from 'components/ui/loader/loader.component';

import styles from './leaderboard-rank.module.less';

interface ILeaderboardRankProps extends ICellRendererParams<IUserBalance, string> {
  getSortParam: () => ReputationPointsCategories;
}

export const LeaderboardRank = memo((props: ILeaderboardRankProps) => {
  const { node, data, getSortParam } = props;

  const rank = useMemo(() => {
    if (!data) {
      return null;
    }

    const sortParam = getSortParam();
    const fieldName = RANK_FIELD_MAPPER[sortParam];

    if (node?.rowPinned) {
      return `#${data[fieldName]}`;
    }

    return data[fieldName];
  }, [node, data, getSortParam]);

  const classNames = useMemo(() => {
    return cn(styles.LeaderboardRank, {
      [styles.LeaderboardRank__gold]: rank === 1,
      [styles.LeaderboardRank__silver]: rank === 2,
      [styles.LeaderboardRank__bronze]: rank === 3,
    });
  }, [rank]);

  if (!rank) {
    return <Loader isLocal isShow size={LoaderSizeEnum.XS} />;
  }

  return <span className={classNames}>{rank}</span>;
});
