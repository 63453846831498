import { FC, memo, SyntheticEvent, useMemo } from 'react';
import cn from 'classnames';

import styles from './rank.module.less';

export enum RankEnum {
  RECTANGLE = 'RECTANGLE',
  SQUARE = 'SQUARE',
}

interface IRankProps {
  type: RankEnum;
  rank: number;
  onClick?: (event: SyntheticEvent) => void;
}

export const Rank: FC<IRankProps> = memo((props: IRankProps) => {
  const { type, rank } = props;

  const rankClasses = useMemo(() => {
    return cn(styles.Rank, {
      [styles['Rank--square']]: type === RankEnum.SQUARE,
      [styles['Rank--rectangle']]: type === RankEnum.RECTANGLE,
      [styles['Rank--level8']]: rank >= 876,
      [styles['Rank--level7']]: rank < 867 && rank >= 751,
      [styles['Rank--level6']]: rank < 751 && rank >= 626,
      [styles['Rank--level5']]: rank < 626 && rank >= 501,
      [styles['Rank--level4']]: rank < 501 && rank >= 376,
      [styles['Rank--level3']]: rank < 376 && rank >= 251,
      [styles['Rank--level2']]: rank < 251 && rank >= 126,
      [styles['Rank--level1']]: rank < 126,
    });
  }, [rank, type]);

  return (
    <div
      role="button"
      tabIndex={0}
      onClick={props.onClick}
      onKeyDown={props.onClick}
      className={rankClasses}
    >
      #{type === RankEnum.SQUARE && rank > 999 ? '999+' : rank}
    </div>
  );
});
