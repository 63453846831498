import { IDayStreakResponse } from 'services/reputation-points/interfaces/day-streak-response.interface';

import { IDaysStreak } from 'stores/reputations-points/interfaces/reputation-points.interface';

export function dayStreakAdapter(dayStreak: IDayStreakResponse): IDaysStreak {
  const { id, max, current, created_at: createdAt, updated_at: updatedAt } = dayStreak;

  return {
    id,
    max,
    current,
    createdAt,
    updatedAt,
  };
}
