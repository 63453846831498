import { inject } from 'inversify';
import { action, makeObservable, reaction } from 'mobx';

import {
  IReputationTransactionResponse,
  IReputationTransactionsResponse,
} from 'services/reputation-points/interfaces/reputation-transaction-response.interface';
import { ITransactionCategoriesAdapter } from 'services/reputation-points/interfaces/transaction-category-response.interface';
import { ReputationPointsService } from 'services/reputation-points/reputationPointsService';

import { AdvancedEntriesStore } from 'stores/advanced-entries/advanced-entries.store';
import { reputationTransactionsAdapter } from 'stores/reputation-transactions/adapters/reputation-transactions-adapter.util';
import { IReputationTransaction } from 'stores/reputation-transactions/interfaces/reputation-transaction.interface';
import { ReputationsPointsStore } from 'stores/reputations-points/reputations-points.store';

import { TYPES } from 'configs/di-types.config';

const TRANSACTIONS_LIMIT = 10;

export class ReputationTransactionsStore extends AdvancedEntriesStore<
  IReputationTransaction,
  IReputationTransactionResponse,
  IReputationTransactionsResponse
> {
  private readonly reputationsPointsStore: ReputationsPointsStore;

  private readonly reputationPointsService: ReputationPointsService;

  constructor(
    @inject<ReputationsPointsStore>(TYPES.ReputationsPointsStore)
    reputationsPointsStore: ReputationsPointsStore,
    @inject<ReputationPointsService>(TYPES.ReputationPointsService)
    reputationPointsService: ReputationPointsService,
  ) {
    super(TRANSACTIONS_LIMIT, false);

    this.reputationsPointsStore = reputationsPointsStore;

    this.reputationPointsService = reputationPointsService;

    makeObservable(this, {
      fetchTransactionsByBucket: action.bound,
    });

    reaction(
      () => JSON.stringify([this.reputationsPointsStore.reputationPopUpData]),
      this.fetchTransactionsByBucket,
    );
  }

  public async fetchTransactionsByBucket() {
    this.reset();

    await super.initialise();
    if (this.reputationsPointsStore.reputationPopUpData?.subTypeId) {
      await this.fetchNextTransactionsByBucket();
    }
  }

  public async fetchNextTransactionsByBucket(): Promise<void> {
    if (this.reputationsPointsStore.reputationPopUpData) {
      await this.retrieveNext(
        this.reputationPointsService.fetchTransactionsByBucket(
          this.reputationsPointsStore.reputationPopUpData,
          this.pagination,
        ),
        <ITransactionCategoriesAdapter>reputationTransactionsAdapter,
      );
    }
  }
}
