import { inject, injectable } from 'inversify';
import { action, makeObservable, observable, reaction } from 'mobx';

import {
  ITransactionCategoriesAdapter,
  ITransactionCategoriesResponse,
  ITransactionCategoryResponse,
} from 'services/reputation-points/interfaces/transaction-category-response.interface';
import { ReputationPointsService } from 'services/reputation-points/reputationPointsService';

import { AdvancedEntriesStore } from 'stores/advanced-entries/advanced-entries.store';
import { ReputationPointsCategories } from 'stores/reputations-points/interfaces/reputation-points.interface';
import { ReputationsPointsStore } from 'stores/reputations-points/reputations-points.store';
import { transactionCategoryAdapter } from 'stores/transaction-buckets/adapters/transaction-category-adapter.util';
import { transactionCategoryToRowAdapter } from 'stores/transaction-buckets/adapters/transaction-catetgory-to-row-adapter.util';
import { ITransactionCategory } from 'stores/transaction-buckets/interfaces/transaction-category.interface';
import { UserPublicStore } from 'stores/user-public/user-public.store';

import { TYPES } from 'configs/di-types.config';

import { ICategoryRowItem } from 'components/reputation-categories/reputation-categories.component';

const BUCKETS_LIMIT = 10;

const DEFAULT_PAGINATION = {
  limit: 3,
  page: 1,
};

@injectable()
export class TransactionBucketsStore extends AdvancedEntriesStore<
  ITransactionCategory,
  ITransactionCategoryResponse,
  ITransactionCategoriesResponse
> {
  private readonly reputationsPointsStore: ReputationsPointsStore;

  private readonly reputationPointsService: ReputationPointsService;

  private readonly userPublicStore: UserPublicStore;

  public reputationPopUpCategories: Maybe<ICategoryRowItem[]>;

  public totalPointsPreview: Maybe<ICategoryRowItem[]>;

  public totalPointsBuckets: number;

  public playerPointsPreview: Maybe<ICategoryRowItem[]>;

  public playerPointsBuckets: number;

  public participationPointsPreview: Maybe<ICategoryRowItem[]>;

  public participationPointsBuckets: number;

  public predictionPointsPreview: Maybe<ICategoryRowItem[]>;

  public predictionPointsBuckets: number;

  constructor(
    @inject<ReputationsPointsStore>(TYPES.ReputationsPointsStore)
    reputationsPointsStore: ReputationsPointsStore,
    @inject<ReputationPointsService>(TYPES.ReputationPointsService)
    reputationPointsService: ReputationPointsService,
    @inject<UserPublicStore>(TYPES.UserPublicStore)
    userPublicStore: UserPublicStore,
  ) {
    super(BUCKETS_LIMIT, false);

    this.reputationsPointsStore = reputationsPointsStore;

    this.reputationPointsService = reputationPointsService;

    this.userPublicStore = userPublicStore;

    this.reputationPopUpCategories = [];

    this.totalPointsPreview = [];

    this.totalPointsBuckets = 0;

    this.playerPointsPreview = [];

    this.playerPointsBuckets = 0;

    this.participationPointsPreview = [];

    this.participationPointsBuckets = 0;

    this.predictionPointsPreview = [];

    this.predictionPointsBuckets = 0;

    makeObservable(this, {
      reputationPopUpCategories: observable,
      totalPointsPreview: observable,
      totalPointsBuckets: observable,
      playerPointsPreview: observable,
      playerPointsBuckets: observable,
      participationPointsPreview: observable,
      participationPointsBuckets: observable,
      predictionPointsPreview: observable,
      predictionPointsBuckets: observable,

      setTotalPointsPreview: action.bound,
      syncPointPreview: action.bound,
      fetchPointsPreview: action.bound,
      fetchBucketsByCategories: action.bound,
      reset: action.bound,
      handleEntriesChange: action.bound,
      setReputationPopUpCategories: action.bound,
      setTotalPointsBuckets: action.bound,
      setPlayerPointsPreview: action.bound,
      setPlayerPointsBuckets: action.bound,
      setParticipationPointsPreview: action.bound,
      setParticipationPointsBuckets: action.bound,
      setPredictionPointsPreview: action.bound,
      setPredictionPointsBuckets: action.bound,
    });

    reaction(
      () =>
        JSON.stringify([
          this.reputationsPointsStore.reputationPopUpData?.username,
          this.reputationsPointsStore.reputationPopUpData?.type,
        ]),
      this.fetchBucketsByCategories,
    );
    reaction(
      () => [this.entries, this.reputationsPointsStore.reputationPopUpData],
      this.handleEntriesChange,
    );

    reaction(() => [this.userPublicStore.userSlug], this.syncPointPreview);
  }

  public syncPointPreview() {
    this.fetchPointsPreview(ReputationPointsCategories.TOTAL_POINTS);
    this.fetchPointsPreview(ReputationPointsCategories.PLAYER_POINTS);
    this.fetchPointsPreview(ReputationPointsCategories.PARTICIPATION_POINTS);
    this.fetchPointsPreview(ReputationPointsCategories.PREDICTION_POINTS);
  }

  public async fetchPointsPreview(type: ReputationPointsCategories) {
    if (!this.userPublicStore.userSlug) {
      return;
    }

    const response = await this.reputationPointsService.fetchUserBalanceDetailed(
      {
        username: this.userPublicStore.userSlug,
        type,
      },
      DEFAULT_PAGINATION,
    );

    if (response.success) {
      const items = response.data.items.map(transactionCategoryAdapter);

      const parsedItems = items.map((item) => {
        return transactionCategoryToRowAdapter(item, type);
      });

      if (type === ReputationPointsCategories.TOTAL_POINTS) {
        this.setTotalPointsPreview(parsedItems);
        this.setTotalPointsBuckets(response.data.count);
      }

      if (type === ReputationPointsCategories.PLAYER_POINTS) {
        this.setPlayerPointsPreview(parsedItems);
        this.setPlayerPointsBuckets(response.data.count);
      }

      if (type === ReputationPointsCategories.PARTICIPATION_POINTS) {
        this.setParticipationPointsPreview(parsedItems);
        this.setParticipationPointsBuckets(response.data.count);
      }

      if (type === ReputationPointsCategories.PREDICTION_POINTS) {
        this.setPredictionPointsPreview(parsedItems);
        this.setPredictionPointsBuckets(response.data.count);
      }
    }
  }

  public async fetchBucketsByCategories() {
    await super.initialise();

    await this.fetchNextBucketsByCategories();
  }

  public async fetchNextBucketsByCategories(): Promise<void> {
    if (this.reputationsPointsStore.reputationPopUpData) {
      await this.retrieveNext(
        this.reputationPointsService.fetchUserBalanceDetailed(
          this.reputationsPointsStore.reputationPopUpData,
          this.pagination,
        ),
        <ITransactionCategoriesAdapter>transactionCategoryAdapter,
      );
    }
  }

  public async handleEntriesChange(): Promise<void> {
    if (this.reputationsPointsStore.reputationPopUpData?.type) {
      this.setReputationPopUpCategories(
        this.entries.map((item) => {
          return transactionCategoryToRowAdapter(
            item,
            this.reputationsPointsStore.reputationPopUpData!.type,
          );
        }),
      );
    }
  }

  public setReputationPopUpCategories(data: Maybe<ICategoryRowItem[]>) {
    this.reputationPopUpCategories = data;
  }

  public setTotalPointsPreview(data: Maybe<ICategoryRowItem[]>) {
    this.totalPointsPreview = data;
  }

  public setTotalPointsBuckets(data: number) {
    this.totalPointsBuckets = data;
  }

  public setPlayerPointsPreview(data: Maybe<ICategoryRowItem[]>) {
    this.playerPointsPreview = data;
  }

  public setPlayerPointsBuckets(data: number) {
    this.playerPointsBuckets = data;
  }

  public setParticipationPointsPreview(data: Maybe<ICategoryRowItem[]>) {
    this.participationPointsPreview = data;
  }

  public setParticipationPointsBuckets(data: number) {
    this.participationPointsBuckets = data;
  }

  public setPredictionPointsPreview(data: Maybe<ICategoryRowItem[]>) {
    this.predictionPointsPreview = data;
  }

  public setPredictionPointsBuckets(data: number) {
    this.predictionPointsBuckets = data;
  }
}
