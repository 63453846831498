import { FC, memo, ReactNode, useMemo } from 'react';
import cn from 'classnames';

import { Button, ButtonSize, ButtonTheme } from 'components/ui/button/button.component';

import styles from './submit-section.module.less';

interface ISubmitSectionProps {
  disabled?: boolean;
  isFormValid: boolean;
  buttonText?: string;
  children?: ReactNode;
}

export const SubmitSection: FC<ISubmitSectionProps> = memo((props: ISubmitSectionProps) => {
  const { disabled = false, isFormValid, buttonText, children } = props;

  const submitSectionClassNames = useMemo(
    () =>
      cn(styles.SubmitSection, {
        [styles['SubmitSection--extended']]: children,
      }),
    [children],
  );

  return (
    <div className={submitSectionClassNames}>
      {props.children}
      <Button
        fluid
        disabled={disabled || !isFormValid}
        theme={ButtonTheme.Primary}
        size={ButtonSize.Big}
        type="submit"
      >
        {buttonText}
      </Button>
    </div>
  );
});
