import { UserPublicProfileTabsContainer } from 'containers/user-public-profile-tabs/user-public-profile-tabs.container';
import { UserPublicStatsContainer } from 'containers/user-public-stats/user-public-stats.container';

import style from './user-public-stat.module.less';

export const UserPublicStatRoute = () => {
  return (
    <div className={style.UserPublicStats}>
      <UserPublicProfileTabsContainer />
      <UserPublicStatsContainer />
    </div>
  );
};
