import { IUserBalanceResponse } from 'services/reputation-points/interfaces/leaderboard-response.interface';

import { IUserBalance } from 'stores/leaderboard/interfaces/leaderboard.interface';

export function userBalanceAdapter(userBalance: IUserBalanceResponse): IUserBalance {
  const {
    user,
    total_points: totalPoints,
    player_points: playerPoints,
    participation_points: participationPoints,
    prediction_points: predictionPoints,
    total_points_rank: totalPointsRank,
    player_points_rank: playerPointsRank,
    participation_points_rank: participationPointsRank,
    prediction_points_rank: predictionPointsRank,
  } = userBalance;

  return {
    user: {
      username: user.username,
      uuid: user.uuid,
      avatarSrc: user.avatar_src,
    },
    totalPoints,
    playerPoints,
    participationPoints,
    predictionPoints,
    totalPointsRank,
    playerPointsRank,
    participationPointsRank,
    predictionPointsRank,
  };
}
