import fireAchievementSrc from 'assets/images/svg/achievements/fire-achievement.svg';

import styles from './days-streak-achievement-badge.module.less';

interface IDaysStreakAchievementProps {
  value: number;
  showMax?: boolean;
}

export const DaysStreakAchievementBadge = (props: IDaysStreakAchievementProps) => {
  const { value, showMax = false } = props;

  return (
    <div className={styles.DaysStreakAchievementBadge}>
      <img src={fireAchievementSrc} alt="fire achievement" />
      <div className={styles.DaysStreakAchievementBadge__Info}>
        <h4>{value}</h4>
        <span className={styles.DaysStreakAchievementBadge__InfoDays}>
          {`day${value === 1 ? '' : 's'}`}
        </span>
        {showMax && <span className={styles.DaysStreakAchievementBadge__InfoMax}>max</span>}
      </div>
    </div>
  );
};
