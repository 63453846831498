import { inject, injectable } from 'inversify';
import { action, autorun, makeObservable, observable, reaction } from 'mobx';

import { ReputationPointsService } from 'services/reputation-points/reputationPointsService';

import { AchievementsStore } from 'stores/achievements/achievements.store';
import { ApiConnectedStore } from 'stores/api-connected/api-connected.store';
import { AuthStore } from 'stores/auth/auth.store';
import { userBalanceAdapter } from 'stores/leaderboard/adapters/user-balance-adapter.util';
import { IUserBalance } from 'stores/leaderboard/interfaces/leaderboard.interface';
import { IReputationTransaction } from 'stores/reputation-transactions/interfaces/reputation-transaction.interface';
import { dayStreakAdapter } from 'stores/reputations-points/adapters/days-streak-adapter.util';
import {
  IDaysStreak,
  IReputationPopupData,
} from 'stores/reputations-points/interfaces/reputation-points.interface';
import { UserPublicStore } from 'stores/user-public/user-public.store';

import { TYPES } from 'configs/di-types.config';

import { IReputationPoints } from 'components/user-details/reputation-box-item/interfaces/reputation-points.interface';

@injectable()
export class ReputationsPointsStore extends ApiConnectedStore {
  private readonly userPublicStore: UserPublicStore;

  private readonly reputationPointsService: ReputationPointsService;

  private readonly authStore: AuthStore;

  private readonly achievementsStore: AchievementsStore;

  public reputationPopUpData: Maybe<IReputationPopupData>;

  public currentUserBalance: Maybe<IUserBalance>;

  public totalReputationPoints: Maybe<IReputationPoints>;

  public userPopUpReputationPoints: Maybe<IReputationPoints>;

  public reputationTransactions: Maybe<IReputationTransaction[]>;

  public daysStreak: Maybe<IDaysStreak>;

  constructor(
    @inject<UserPublicStore>(TYPES.UserPublicStore)
    userPublicStore: UserPublicStore,
    @inject<ReputationPointsService>(TYPES.ReputationPointsService)
    reputationPointsService: ReputationPointsService,
    @inject(TYPES.AuthStore) authStore: AuthStore,
    @inject(TYPES.AchievementsStore) achievementsStore: AchievementsStore,
  ) {
    super();

    this.authStore = authStore;

    this.userPublicStore = userPublicStore;

    this.achievementsStore = achievementsStore;

    this.reputationPointsService = reputationPointsService;

    this.currentUserBalance = null;

    this.reputationPopUpData = null;

    this.reputationTransactions = null;

    this.totalReputationPoints = null;

    this.userPopUpReputationPoints = null;

    this.daysStreak = null;

    makeObservable(this, {
      reputationPopUpData: observable,
      totalReputationPoints: observable,
      reputationTransactions: observable,
      userPopUpReputationPoints: observable,
      daysStreak: observable,

      setReputationTransactions: action.bound,
      setTotalReputationPoints: action.bound,
      setReputationPopUpData: action.bound,
      setUserPopUpReputationPoints: action.bound,
      requestPublicUserBalance: action.bound,
      retrieveDaysStreak: action.bound,
      fetchCurrentUserBalance: action.bound,
    });

    reaction(
      () => [this.authStore.isAuthorised, this.authStore.userMe],
      () => {
        this.fetchCurrentUserBalance();
        this.retrieveDaysStreak();
      },
    );
    reaction(() => [this.userPublicStore.userSlug], this.requestPublicUserBalance);
    reaction(
      () => [this.userPublicStore?.userPopUpDetails?.username],
      this.requestUserPopUpReputationPoints,
    );
    reaction(() => [this.achievementsStore.seasonId], this.retrieveDaysStreak);
    autorun(() => this.fetchCurrentUserBalance());
  }

  public requestPublicUserBalance = async () => {
    if (!this.userPublicStore.userSlug) {
      this.setTotalReputationPoints(null);
      return;
    }

    this.setFetched(false);
    this.setFetching(true);

    const response = await this.reputationPointsService.fetchUserBalance(
      this.userPublicStore.userSlug,
    );

    if (response.success) {
      const userBalance = response.data ? userBalanceAdapter(response.data) : null;

      this.setTotalReputationPoints(userBalance);
    } else {
      this.setErrors(response.errors);
      this.setTotalReputationPoints(null);
    }

    this.setFetching(false);
    this.setFetched(true);
  };

  public setCurrentUserBalance(userBalance: Maybe<IUserBalance>) {
    this.currentUserBalance = userBalance;
  }

  public setReputationPopUpData(data: Maybe<IReputationPopupData>) {
    this.reputationPopUpData = data;
  }

  public setReputationTransactions(data: Maybe<IReputationTransaction[]>) {
    this.reputationTransactions = data;
  }

  public setTotalReputationPoints(data: Maybe<IReputationPoints>) {
    this.totalReputationPoints = data;
  }

  public setUserPopUpReputationPoints(data: Maybe<IReputationPoints>) {
    this.userPopUpReputationPoints = data;
  }

  public setDaysStreak(data: Maybe<IDaysStreak>) {
    this.daysStreak = data;
  }

  public async fetchCurrentUserBalance() {
    if (!this.authStore.isAuthorised || !this.authStore.userMe) {
      this.setCurrentUserBalance(null);
      return;
    }

    this.setFetched(false);
    this.setFetching(true);

    const response = await this.reputationPointsService.fetchUserBalance(
      this.authStore.userMe.username,
    );

    if (response.success) {
      const userBalance = response.data ? userBalanceAdapter(response.data) : null;

      this.setCurrentUserBalance(userBalance);
    } else {
      this.setErrors(response.errors);
      this.setCurrentUserBalance(null);
    }

    this.setFetching(false);
    this.setFetched(true);
  }

  public async retrieveDaysStreak() {
    if (
      !this.authStore.isAuthorised ||
      !this.authStore.userMe ||
      !this.achievementsStore.seasonId
    ) {
      this.setDaysStreak(null);
      return;
    }

    this.setFetched(false);
    this.setFetching(true);

    const response = await this.reputationPointsService.fetchDaysStreak(
      this.authStore.userMe.username,
      this.achievementsStore.seasonId,
    );
    if (response.success) {
      this.setDaysStreak(response.data ? dayStreakAdapter(response.data) : null);
    } else {
      this.setErrors(response.errors);
      this.setDaysStreak(null);
    }

    this.setFetching(false);
    this.setFetched(true);
  }

  public requestUserPopUpReputationPoints = async () => {
    if (!this.userPublicStore?.userPopUpDetails?.username) {
      this.setUserPopUpReputationPoints(null);
      return;
    }

    this.setFetched(false);
    this.setFetching(true);

    const response = await this.reputationPointsService.fetchUserBalance(
      this.userPublicStore.userPopUpDetails.username.slice(1),
    );

    if (response.success) {
      const userBalance = response.data ? userBalanceAdapter(response.data) : null;

      this.setUserPopUpReputationPoints(userBalance);
    } else {
      this.setErrors(response.errors);
      this.setUserPopUpReputationPoints(null);
    }

    this.setFetching(false);
    this.setFetched(true);
  };
}
