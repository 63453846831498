import { memo } from 'react';

import { CircularProgressBar } from 'components/ui/circular-progress-bar/circular-progress-bar.component';

import bronzeProgressBar from 'assets/images/svg/achievements/bronze-progress-bar.svg';
import goldProgressBar from 'assets/images/svg/achievements/gold-progress-bar.svg';
import platinumProgressBar from 'assets/images/svg/achievements/platinum-progress-bar.svg';
import silverProgressBar from 'assets/images/svg/achievements/silver-progress-bar.svg';

import styles from './achievement-progress-bar.module.less';

export enum AchievementProgressBarTheme {
  Default = 'default',
  Bronze = 'bronze',
  Silver = 'silver',
  Gold = 'gold',
  Platinum = 'platinum',
}

export interface IAchievementProgressBarProps {
  fill?: number;
  theme: AchievementProgressBarTheme;
}

export const AchievementProgressBar = memo((props: IAchievementProgressBarProps) => {
  const { fill = 0, theme } = props;

  return (
    <div className={styles.AchievementProgressBar}>
      {theme === AchievementProgressBarTheme.Bronze && (
        <img src={bronzeProgressBar} alt="bronze progress bar" />
      )}
      {theme === AchievementProgressBarTheme.Silver && (
        <img src={silverProgressBar} alt="silver progress bar" />
      )}
      {theme === AchievementProgressBarTheme.Gold && (
        <img src={goldProgressBar} alt="gold progress bar" />
      )}
      {theme === AchievementProgressBarTheme.Platinum && (
        <img src={platinumProgressBar} alt="platinum progress bar" />
      )}
      {theme === AchievementProgressBarTheme.Default && <CircularProgressBar fill={fill} />}
    </div>
  );
});
