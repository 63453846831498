import { FC, memo, SyntheticEvent, useMemo } from 'react';

import { IReputationTransaction } from 'stores/reputation-transactions/interfaces/reputation-transaction.interface';
import { ReputationPointsCategories } from 'stores/reputations-points/interfaces/reputation-points.interface';

import { capitalizeEachWord } from 'helpers/string/capitalize-each-word.util';

import {
  ICategoryData,
  ICategoryRowItem,
  ReputationCategories,
} from 'components/reputation-categories/reputation-categories.component';
import { ReputationTransactions } from 'components/reputation-transactions/reputation-transactions.component';
import { IconFontName } from 'components/ui/icon-font/icon-font.component';

interface IReputationPointsPopUpProps {
  loading: boolean;
  type: ReputationPointsCategories;
  subTypeId?: Maybe<number>;
  items: Maybe<ICategoryRowItem[]>;
  reputationTransactions: Maybe<IReputationTransaction[]>;
  categoryData: ICategoryData;
  onRowClick: (id: number) => void;
  onBackClick: () => void;
  onCloseClick: () => void;
  onRankClick: (
    event: SyntheticEvent,
    type: ReputationPointsCategories,
    bucketId: Maybe<string>,
  ) => void;
  hasMoreTransactions: boolean;
  hasMoreCategories: boolean;
  onLoadMoreCategories: () => void;
  onLoadMoreTransactions: () => void;
}

export const ReputationPointsPopUp: FC<IReputationPointsPopUpProps> = memo(
  (props: IReputationPointsPopUpProps) => {
    const { loading, type, items, subTypeId, categoryData, reputationTransactions } = props;

    const iconName = useMemo(() => {
      if (type === ReputationPointsCategories.TOTAL_POINTS) {
        return IconFontName.StarFilled;
      }
      if (type === ReputationPointsCategories.PLAYER_POINTS) {
        return IconFontName.SuperLikeFilled;
      }
      if (type === ReputationPointsCategories.PREDICTION_POINTS) {
        return IconFontName.Chip;
      }

      return IconFontName.LikeFiled;
    }, [type]);

    const capitalizedType = useMemo(() => {
      return capitalizeEachWord(type.replace(/_/g, ' '));
    }, [type]);

    const currentSubCategoryData = useMemo(() => {
      return items?.find((item) => item.id === subTypeId);
    }, [items, subTypeId]);

    return subTypeId ? (
      <ReputationTransactions
        iconName={iconName}
        capitalizedType={capitalizedType}
        currentSubCategoryData={currentSubCategoryData}
        loading={loading}
        type={type}
        categoryData={categoryData}
        onRankClick={props.onRankClick}
        onBackClick={props.onBackClick}
        reputationTransactions={reputationTransactions}
        hasMore={props.hasMoreTransactions}
        onLoadMore={props.onLoadMoreTransactions}
        onCloseClick={props.onCloseClick}
      />
    ) : (
      <ReputationCategories
        isShowTotalRow
        iconName={iconName}
        capitalizedType={capitalizedType}
        loading={loading}
        type={type}
        items={items}
        categoryData={categoryData}
        onRowClick={props.onRowClick}
        onRankClick={props.onRankClick}
        hasMore={props.hasMoreCategories}
        onLoadMore={props.onLoadMoreCategories}
        onCloseClick={props.onCloseClick}
      />
    );
  },
);
