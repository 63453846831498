import { useInjection } from 'inversify-react';
import { observer } from 'mobx-react-lite';

import { AchievementsStore } from 'stores/achievements/achievements.store';
import { ReputationsPointsStore } from 'stores/reputations-points/reputations-points.store';
import { SeasonsStore } from 'stores/seasons/seasons.store';

import { TYPES } from 'configs/di-types.config';

import { Select } from 'components/ui/form-fields/select/select.component';
import { UserAchievements } from 'components/user-achievements/user-achievements.component';

import styles from './user-achievements-container.module.less';

export const UserPublicAchievementsContainer = observer(() => {
  const seasonsStore = useInjection<SeasonsStore>(TYPES.SeasonsStore);
  const reputationsPointsStore = useInjection<ReputationsPointsStore>(TYPES.ReputationsPointsStore);
  const achievementsStore = useInjection<AchievementsStore>(TYPES.AchievementsStore);

  return (
    <div className={styles.UserPublicAchievements}>
      <div className={styles.UserPublicAchievements__Filter}>
        <Select
          id="achievement_season"
          name="AchievementSeason"
          options={seasonsStore.reputationSeasonsSelectOptions}
          value={achievementsStore.seasonId ?? ''}
          onChange={achievementsStore.setSeasonId}
        />
      </div>
      <UserAchievements daysStreak={reputationsPointsStore.daysStreak} />
    </div>
  );
});
