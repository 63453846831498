import { SyntheticEvent, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { useInjection } from 'inversify-react';
import { observer } from 'mobx-react-lite';

import { AuthStore } from 'stores/auth/auth.store';
import { LeaderboardStore } from 'stores/leaderboard/leaderboard.store';
import { ReputationPointsCategories } from 'stores/reputations-points/interfaces/reputation-points.interface';
import { ReputationsPointsStore } from 'stores/reputations-points/reputations-points.store';
import { TransactionBucketsStore } from 'stores/transaction-buckets/transaction-buckets.store';
import { UserPublicStore } from 'stores/user-public/user-public.store';

import { TYPES } from 'configs/di-types.config';
import { getPath } from 'helpers/get-path.util';
import * as path from 'routes/paths.constants';

import { DaysStreakAchievementBadge } from 'components/achievements/days-streak-achievement-badge/days-streak-achievement-badge.component';
import { ReputationCategories } from 'components/reputation-categories/reputation-categories.component';
import { Button, ButtonSize, ButtonTheme } from 'components/ui/button/button.component';
import { IconFontName } from 'components/ui/icon-font/icon-font.component';
import { Loader } from 'components/ui/loader/loader.component';
import { IReputationBoxItem } from 'components/user-details/reputation-box-item/interfaces/reputation-box-item.interface';
import { ReputationBoxItem } from 'components/user-details/reputation-box-item/reputation-box-item.component';
import { defaultReputationPoints } from 'components/user-details/user-details.component';
import { prepareReputationPointsItems } from 'components/user-details/utils/prepare-reputation-points-items';

import styles from './user-public-stats.module.less';

const MAX_BUCKETS_ON_PAGE = 3;
const DEFAULT_POINTS_VALUE = 0;

export const UserPublicStatsContainer = observer(() => {
  const reputationsPointsStore = useInjection<ReputationsPointsStore>(TYPES.ReputationsPointsStore);

  const transactionBucketsStore = useInjection<TransactionBucketsStore>(
    TYPES.TransactionBucketsStore,
  );

  const userPublicStore = useInjection<UserPublicStore>(TYPES.UserPublicStore);

  const authStore = useInjection<AuthStore>(TYPES.AuthStore);

  const leaderboardStore = useInjection<LeaderboardStore>(TYPES.LeaderboardStore);

  const navigate = useNavigate();

  const reputationPointsBoxItems = useMemo<IReputationBoxItem[]>(() => {
    if (!reputationsPointsStore.totalReputationPoints) {
      return prepareReputationPointsItems(defaultReputationPoints);
    }
    return prepareReputationPointsItems(reputationsPointsStore.totalReputationPoints);
  }, [reputationsPointsStore.totalReputationPoints]);

  const handleLeaderboardClick = useCallback(() => {
    navigate(path.LEADERBOARD);
  }, [navigate]);

  const handleSeeAllAchievementsClick = useCallback(() => {
    if (userPublicStore.userPublic?.username) {
      navigate(
        getPath(path.USER_PUBLIC_ACHIEVEMENTS, {
          [path.USERNAME_PARAM]: userPublicStore.userPublic.username,
        }),
      );
    }
  }, [navigate, userPublicStore.userPublic?.username]);

  const handlePointSectionClick = useCallback(
    (type: ReputationPointsCategories) => {
      if (userPublicStore.userPublic?.username) {
        reputationsPointsStore.setReputationPopUpData({
          type,
          subTypeId: null,
          username: userPublicStore.userPublic.username,
        });
      }
    },
    [reputationsPointsStore, userPublicStore.userPublic?.username],
  );

  const handleRankClick = useCallback(
    (event: SyntheticEvent, type: ReputationPointsCategories, bucketId: Maybe<string>) => {
      event.stopPropagation();
      navigate(path.LEADERBOARD);
      leaderboardStore.setSortParam(type);

      if (bucketId) {
        leaderboardStore.setBucketId(bucketId);
      }
    },
    [navigate, leaderboardStore],
  );

  const handleCategoryClick = useCallback(
    (type: ReputationPointsCategories) => (id: number) => {
      if (
        authStore.userMe?.username &&
        userPublicStore.userPublic?.username &&
        authStore.userMe?.username !== userPublicStore.userPublic?.username
      ) {
        return;
      }

      if (userPublicStore.userPublic?.username) {
        reputationsPointsStore.setReputationPopUpData({
          subTypeId: id,
          type,
          username: userPublicStore.userPublic.username,
        });
      }
    },
    [reputationsPointsStore, userPublicStore.userPublic?.username, authStore.userMe?.username],
  );

  return (
    <div className={styles.UserPublicStatsContainer}>
      <div className={styles.UserPublicStatsContainer__ReputationHeader}>
        <div className={styles.UserPublicStatsContainer__Title}>Reputation points</div>
        <Button theme={ButtonTheme.Text} size={ButtonSize.Big} onClick={handleLeaderboardClick}>
          Leaderboard
        </Button>
      </div>
      <div className={styles.UserPublicStatsContainer__ReputationPoints}>
        {reputationPointsBoxItems.map((item) => (
          <ReputationBoxItem
            type={item.type}
            key={item.title}
            title={item.title}
            content={item.content}
            rank={item.rank}
            onClick={() => handlePointSectionClick(item.type)}
          />
        ))}
      </div>
      <div className={styles.UserPublicStatsContainer__ReputationHeader}>
        <div className={styles.UserPublicStatsContainer__Title}>Achievements</div>
        <Button
          theme={ButtonTheme.Text}
          size={ButtonSize.Big}
          onClick={handleSeeAllAchievementsClick}
        >
          See All
        </Button>
      </div>
      {reputationsPointsStore.daysStreak && (
        <div className={styles.UserPublicStatsContainer__Achievements}>
          <DaysStreakAchievementBadge value={reputationsPointsStore.daysStreak.current} />
        </div>
      )}
      <div className={styles.UserPublicStatsContainer__Section}>
        <div className={styles.UserPublicStatsContainer__Header}>
          <div>Total points</div>
        </div>
        <ReputationCategories
          isShowTotalRow={false}
          type={ReputationPointsCategories.TOTAL_POINTS}
          items={transactionBucketsStore.totalPointsPreview}
          categoryData={{
            rank:
              reputationsPointsStore.totalReputationPoints?.totalPointsRank || DEFAULT_POINTS_VALUE,
            value:
              reputationsPointsStore.totalReputationPoints?.totalPoints || DEFAULT_POINTS_VALUE,
          }}
          onRankClick={handleRankClick}
          onRowClick={handleCategoryClick(ReputationPointsCategories.TOTAL_POINTS)}
          iconName={IconFontName.StarFilled}
        />
        {transactionBucketsStore.totalPointsBuckets > MAX_BUCKETS_ON_PAGE && (
          <Button
            className={styles.MoreButton}
            theme={ButtonTheme.Secondary}
            size={ButtonSize.Small}
            fluid
            onClick={() => handlePointSectionClick(ReputationPointsCategories.TOTAL_POINTS)}
          >
            + {transactionBucketsStore.totalPointsBuckets - MAX_BUCKETS_ON_PAGE} more
          </Button>
        )}
      </div>
      <div className={styles.UserPublicStatsContainer__Section}>
        <div className={styles.UserPublicStatsContainer__Header}>
          <div>Player points</div>
        </div>
        <ReputationCategories
          isShowTotalRow={false}
          type={ReputationPointsCategories.PLAYER_POINTS}
          items={transactionBucketsStore.playerPointsPreview}
          categoryData={{
            rank:
              reputationsPointsStore.totalReputationPoints?.playerPointsRank ||
              DEFAULT_POINTS_VALUE,
            value:
              reputationsPointsStore.totalReputationPoints?.playerPoints || DEFAULT_POINTS_VALUE,
          }}
          onRankClick={handleRankClick}
          onRowClick={handleCategoryClick(ReputationPointsCategories.PLAYER_POINTS)}
          iconName={IconFontName.StarFilled}
        />
        {transactionBucketsStore.playerPointsBuckets > MAX_BUCKETS_ON_PAGE && (
          <Button
            className={styles.MoreButton}
            theme={ButtonTheme.Secondary}
            size={ButtonSize.Small}
            fluid
            onClick={() => handlePointSectionClick(ReputationPointsCategories.PLAYER_POINTS)}
          >
            + {transactionBucketsStore.playerPointsBuckets - MAX_BUCKETS_ON_PAGE} more
          </Button>
        )}
      </div>
      <div className={styles.UserPublicStatsContainer__Section}>
        <div className={styles.UserPublicStatsContainer__Header}>
          <div>Participation points</div>
        </div>
        <ReputationCategories
          isShowTotalRow={false}
          type={ReputationPointsCategories.PARTICIPATION_POINTS}
          items={transactionBucketsStore.participationPointsPreview}
          categoryData={{
            rank:
              reputationsPointsStore.totalReputationPoints?.participationPointsRank ||
              DEFAULT_POINTS_VALUE,
            value:
              reputationsPointsStore.totalReputationPoints?.participationPoints ||
              DEFAULT_POINTS_VALUE,
          }}
          onRankClick={handleRankClick}
          onRowClick={handleCategoryClick(ReputationPointsCategories.PARTICIPATION_POINTS)}
          iconName={IconFontName.StarFilled}
        />
        {transactionBucketsStore.participationPointsBuckets > MAX_BUCKETS_ON_PAGE && (
          <Button
            className={styles.MoreButton}
            theme={ButtonTheme.Secondary}
            size={ButtonSize.Small}
            fluid
            onClick={() => handlePointSectionClick(ReputationPointsCategories.PARTICIPATION_POINTS)}
          >
            + {transactionBucketsStore.participationPointsBuckets - MAX_BUCKETS_ON_PAGE} more
          </Button>
        )}
      </div>
      <div className={styles.UserPublicStatsContainer__Section}>
        <div className={styles.UserPublicStatsContainer__Header}>
          <div>Prediction points</div>
        </div>
        <ReputationCategories
          isShowTotalRow={false}
          type={ReputationPointsCategories.PREDICTION_POINTS}
          items={transactionBucketsStore.predictionPointsPreview}
          categoryData={{
            rank:
              reputationsPointsStore.totalReputationPoints?.predictionPointsRank ||
              DEFAULT_POINTS_VALUE,
            value:
              reputationsPointsStore.totalReputationPoints?.predictionPoints ||
              DEFAULT_POINTS_VALUE,
          }}
          onRankClick={handleRankClick}
          onRowClick={handleCategoryClick(ReputationPointsCategories.PREDICTION_POINTS)}
          iconName={IconFontName.StarFilled}
        />
        {transactionBucketsStore.predictionPointsBuckets > MAX_BUCKETS_ON_PAGE && (
          <Button
            className={styles.MoreButton}
            theme={ButtonTheme.Secondary}
            size={ButtonSize.Small}
            fluid
            onClick={() => handlePointSectionClick(ReputationPointsCategories.PREDICTION_POINTS)}
          >
            + {transactionBucketsStore.predictionPointsBuckets - MAX_BUCKETS_ON_PAGE} more
          </Button>
        )}
        <Loader isShow={reputationsPointsStore.fetching} isLocal />
      </div>
    </div>
  );
});
