import { ReputationPointsCategories } from 'stores/reputations-points/interfaces/reputation-points.interface';

import { IReputationBoxItem } from 'components/user-details/reputation-box-item/interfaces/reputation-box-item.interface';
import { IReputationPoints } from 'components/user-details/reputation-box-item/interfaces/reputation-points.interface';

export const prepareReputationPointsItems = (points: IReputationPoints): IReputationBoxItem[] => {
  return [
    {
      title: 'Total',
      content: points.totalPoints,
      rank: points.totalPointsRank,
      type: ReputationPointsCategories.TOTAL_POINTS,
    },
    {
      title: 'Player',
      content: points.playerPoints,
      rank: points.playerPointsRank,
      type: ReputationPointsCategories.PLAYER_POINTS,
    },
    {
      title: 'Participation',
      content: points.participationPoints,
      rank: points.participationPointsRank,
      type: ReputationPointsCategories.PARTICIPATION_POINTS,
    },
    {
      title: 'Prediction',
      content: points.predictionPoints,
      rank: points.predictionPointsRank,
      type: ReputationPointsCategories.PREDICTION_POINTS,
    },
  ];
};
