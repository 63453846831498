import { IPagination } from 'interfaces/pagination.interface';
import { inject, injectable } from 'inversify';
import { SortType } from 'types/sort-type.type';

import { USER_SLUG_API_PARAM } from 'services/http/consts/api-endpoint-params.constants';
import {
  REPUTATION_DAYS_STREAK,
  REPUTATION_TRANSACTIONS,
  REPUTATION_USER_BALANCE,
  REPUTATION_USER_BALANCE_DETAILED,
  REPUTATION_USERS_BALANCES,
} from 'services/http/consts/api-endpoints.constants';
import { HttpService } from 'services/http/http.service';
import { IDayStreakResponse } from 'services/reputation-points/interfaces/day-streak-response.interface';
import {
  IUserBalanceResponse,
  IUserBalancesResponse,
} from 'services/reputation-points/interfaces/leaderboard-response.interface';
import { IReputationTransactionsResponse } from 'services/reputation-points/interfaces/reputation-transaction-response.interface';
import { ITransactionCategoriesResponse } from 'services/reputation-points/interfaces/transaction-category-response.interface';

import {
  IReputationPopupData,
  ReputationPointsCategories,
} from 'stores/reputations-points/interfaces/reputation-points.interface';
import {
  DEFAULT_CATEGORIES_PAGINATION,
  DEFAULT_SEASONS_PAGINATION,
  DEFAULT_TRANSACTIONS_PAGINATION,
} from 'stores/seasons/seasons.config';

import { TYPES } from 'configs/di-types.config';

@injectable()
export class ReputationPointsService {
  private readonly httpService: HttpService;

  constructor(@inject<HttpService>(TYPES.HttpService) httpService: HttpService) {
    this.httpService = httpService;
  }

  public fetchUserBalances(
    seasonId: Maybe<string>,
    bucketId: Maybe<string>,
    pagination: IPagination = DEFAULT_SEASONS_PAGINATION,
    order: SortType = 'DESC',
    sortParam: ReputationPointsCategories = ReputationPointsCategories.TOTAL_POINTS,
  ): Promise<IResponse<IUserBalancesResponse>> {
    const { limit, page } = pagination;

    const params: Record<string, string> = {
      limit: String(limit),
      page: String(page),
      order,
      sort: sortParam,
    };

    if (seasonId) {
      params.season_id = seasonId;
    }

    if (bucketId && Number(bucketId)) {
      params.bucket_id = bucketId;
    }

    return this.httpService.request<IUserBalancesResponse>({
      method: 'GET',
      url: REPUTATION_USERS_BALANCES,
      params: new URLSearchParams(params),
    });
  }

  public fetchUserBalance(
    username: string,
    seasonId?: Maybe<string>,
    bucketId?: Maybe<string>,
  ): Promise<IResponse<IUserBalanceResponse>> {
    const params: Record<string, string> = {};

    if (bucketId && Number(bucketId)) {
      params.bucket_id = bucketId;
    }

    if (seasonId) {
      params.seasonId = seasonId;
    }

    return this.httpService.request<IUserBalanceResponse>({
      method: 'GET',
      url: REPUTATION_USER_BALANCE,
      routeParams: { [USER_SLUG_API_PARAM]: username },
      params: new URLSearchParams(params),
    });
  }

  public fetchUserBalanceDetailed(
    payload: IReputationPopupData,
    pagination: IPagination = DEFAULT_CATEGORIES_PAGINATION,
  ): Promise<IResponse<ITransactionCategoriesResponse>> {
    const { limit, page } = pagination;
    const params: Record<string, string> = {
      limit: String(limit),
      page: String(page),
      category: payload.type,
    };

    return this.httpService.request<ITransactionCategoriesResponse>({
      method: 'GET',
      url: REPUTATION_USER_BALANCE_DETAILED,
      routeParams: { [USER_SLUG_API_PARAM]: payload.username },
      params: new URLSearchParams(params),
    });
  }

  public fetchTransactionsByBucket(
    payload: IReputationPopupData,
    pagination: IPagination = DEFAULT_TRANSACTIONS_PAGINATION,
  ): Promise<IResponse<IReputationTransactionsResponse>> {
    const { limit, page } = pagination;
    const params: Record<string, string> = {
      limit: String(limit),
      page: String(page),
      category: payload.type,
    };

    if (payload.subTypeId) {
      params.bucket_id = payload.subTypeId.toString();
    }

    return this.httpService.request<IReputationTransactionsResponse>({
      method: 'GET',
      url: REPUTATION_TRANSACTIONS,
      params: new URLSearchParams(params),
    });
  }

  public fetchDaysStreak(
    username: string,
    seasonId?: Maybe<string>,
  ): Promise<IResponse<IDayStreakResponse>> {
    const params: Record<string, string> = {};

    if (seasonId) {
      params.season_id = seasonId;
    }

    return this.httpService.request<IDayStreakResponse>({
      method: 'GET',
      url: REPUTATION_DAYS_STREAK,
      routeParams: { [USER_SLUG_API_PARAM]: username },
      params: new URLSearchParams(params),
    });
  }
}
