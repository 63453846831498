import { FC, useCallback, useMemo } from 'react';
import cn from 'classnames';

import { ISocialLink, LinkType } from 'stores/auth/interfaces/custom-link.interface';

import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';

import { useKeyboardTrigger } from 'hooks/use-keyboard-trigger';
import { useMainProvider } from 'hooks/use-main-provider';
import { useResponsive } from 'hooks/use-responsive';

import { BaseIonBottomSheet } from 'components/bottom-sheet/base-ion-bottom-sheet/base-ion-bottom-sheet.component';
import {
  SocialLinksForm,
  SocialLinksFormDataType,
} from 'components/forms/social-links/social-links-form.component';
import {
  BaseModalComponent,
  ModalWindowSize,
} from 'components/modals/base-modal/base-modal.component';
import { getLinkValueByKey } from 'components/player-profile/player-profile-overview/player-profile-overview.component';
import { IconFont, IconFontName, IconFontSize } from 'components/ui/icon-font/icon-font.component';

import styles from './social-links-popup.module.less';

const MIN_BREAKPOINT = 0;
const INITIAL_BREAKPOINT = 0.7;

interface ISocialLinksPopupProps {
  visible: boolean;
  onClose: () => void;
  links: ISocialLink[];
  onDeleteClick: () => void;
  onSubmitClick: (payload: SocialLinksFormDataType) => void;
}

export const SocialLinksPopup: FC<ISocialLinksPopupProps> = (props) => {
  const { visible, links, onSubmitClick } = props;

  const { isNativeApp } = useMainProvider();
  const { isKeyboardOpen } = useKeyboardTrigger(isNativeApp);

  const initialValues = useMemo(() => {
    if (!links || !links.length) {
      return {
        instagram: '',
        twitter: '',
        youtube: '',
        tiktok: '',
        snapchat: '',
        discord: '',
      };
    }

    return {
      instagram: getLinkValueByKey(links, LinkType.Instagram) ?? '',
      twitter: getLinkValueByKey(links, LinkType.Twitter) ?? '',
      youtube: getLinkValueByKey(links, LinkType.Youtube) ?? '',
      tiktok: getLinkValueByKey(links, LinkType.TikTok) ?? '',
      snapchat: getLinkValueByKey(links, LinkType.Snapchat) ?? '',
      discord: getLinkValueByKey(links, LinkType.Discord) ?? '',
    };
  }, [links]);

  const handleSubmitClick = useCallback(
    (payload: SocialLinksFormDataType) => {
      onSubmitClick(payload);
    },
    [onSubmitClick],
  );

  const wrapperClasses = useMemo<string>(() => {
    return cn(styles.Wrapper, {
      [styles['Wrapper--keyboard']]: isKeyboardOpen,
    });
  }, [isKeyboardOpen]);

  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);

  if (isDesktopPlus) {
    return (
      <BaseModalComponent
        visible={visible}
        onClose={props.onClose}
        title="Edit Social Links"
        size={ModalWindowSize.L}
        closeOnOverlayClick={false}
      >
        <SocialLinksForm
          onDeleteClick={props.onDeleteClick}
          submitText="Save"
          onSubmit={handleSubmitClick}
          {...(initialValues && { initialValues })}
        />
      </BaseModalComponent>
    );
  }

  return (
    <BaseIonBottomSheet
      visible={visible}
      isAutoHeight
      safeAreaBottom={0}
      breakpoints={[MIN_BREAKPOINT, INITIAL_BREAKPOINT]}
      initialBreakpoint={INITIAL_BREAKPOINT}
      onClose={props.onClose}
    >
      <div className={wrapperClasses}>
        <div className={styles.SocialLinkHeader}>
          <div className={styles.SocialLinkHeader__Title}>Edit Social Links</div>

          <button className={styles.SocialLinkHeader__Button} onClick={props.onDeleteClick}>
            <IconFont name={IconFontName.Remove} size={IconFontSize.Small} />
            <span className={styles.SocialLinkHeader__ButtonTitle}>Clear All</span>
          </button>
        </div>
        <SocialLinksForm
          onDeleteClick={props.onDeleteClick}
          submitText="Save"
          onSubmit={handleSubmitClick}
          {...(initialValues && { initialValues })}
        />
      </div>
    </BaseIonBottomSheet>
  );
};
