import { ReputationPointsCategories } from 'stores/reputations-points/interfaces/reputation-points.interface';
import { ITransactionCategory } from 'stores/transaction-buckets/interfaces/transaction-category.interface';

import { ICategoryRowItem } from 'components/reputation-categories/reputation-categories.component';

export function transactionCategoryToRowAdapter(
  item: ITransactionCategory,
  selectedCategory: ReputationPointsCategories,
): ICategoryRowItem {
  const {
    bucket,
    totalPoints,
    playerPoints,
    participationPoints,
    predictionPoints,
    totalPointsRank,
    playerPointsRank,
    participationPointsRank,
    predictionPointsRank,
  } = item;

  const row = {
    id: bucket.id,
    title: bucket.data.name,
    titleSrc: bucket.data.logoSrc,
  };

  if (selectedCategory === ReputationPointsCategories.TOTAL_POINTS) {
    return {
      ...row,
      value: totalPoints,
      rank: totalPointsRank,
    };
  }

  if (selectedCategory === ReputationPointsCategories.PLAYER_POINTS) {
    return {
      ...row,
      value: playerPoints,
      rank: playerPointsRank,
    };
  }

  if (selectedCategory === ReputationPointsCategories.PREDICTION_POINTS) {
    return {
      ...row,
      value: predictionPoints,
      rank: predictionPointsRank,
    };
  }

  return {
    ...row,
    value: participationPoints,
    rank: participationPointsRank,
  };
}
