import { memo, useCallback } from 'react';

import { ISocialLink, LinkType } from 'stores/auth/interfaces/custom-link.interface';
import { getPreviewImage } from 'stores/gallery/utils/get-preview-image.util';
import { IPlayerDetails } from 'stores/player/interfaces/player-profile.interfaces';
import { IInteractiveMediaAttachment } from 'stores/posts/interfaces/post.interface';

import { ENCODED_BULLET } from 'configs/encoded-symbols.config';
import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';

import { useResponsive } from 'hooks/use-responsive';

import playerProfileStyles from 'components/player-profile/player-profile.module.less';
import { getLinkValueByKey } from 'components/player-profile/player-profile-overview/player-profile-overview.component';
import { ProfileInfoSocials } from 'components/profile-info-socials/profile-info-socials.component';
import { Avatar, AvatarSize } from 'components/ui/avatar/avatar.component';
import { Button, ButtonSize, ButtonTheme } from 'components/ui/button/button.component';
import { IconFontName, IconFontSize } from 'components/ui/icon-font/icon-font.component';

import styles from './player-profile-header-info.module.less';

interface IPlayerProfileInfoHeaderProps {
  isFollowFetching: boolean;
  isFollowFetched: boolean;
  isFollowedPlayer: boolean;
  playerDetails: Maybe<IPlayerDetails>;
  onTeamClick: (teamId: number) => void;
  onOpenPreview: (image: IInteractiveMediaAttachment) => void;
  onFollowPlayer: () => void;
  socialLinks: ISocialLink[];
}

export const PlayerProfileHeaderInfo = memo((props: IPlayerProfileInfoHeaderProps) => {
  const {
    socialLinks,
    isFollowFetching,
    isFollowFetched,
    isFollowedPlayer,
    playerDetails,
    onTeamClick,
    onOpenPreview,
  } = props;

  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);

  const handleTeamClick = useCallback(() => {
    if (playerDetails?.team?.id) {
      onTeamClick(playerDetails.team.id);
    }
  }, [onTeamClick, playerDetails?.team?.id]);

  const handleOpenPreview = useCallback(() => {
    if (!playerDetails?.mediumLogoUrl) {
      return;
    }

    const image = getPreviewImage(
      playerDetails.mediumLogoUrl,
      `${playerDetails.firstname.toLowerCase()}-${playerDetails.lastname.toLowerCase()}-logo`,
      playerDetails.smallLogoUrl,
    );

    onOpenPreview(image);
  }, [onOpenPreview, playerDetails]);

  if (!playerDetails) {
    return null;
  }

  return (
    <div className={styles.PlayerProfileInfo}>
      <div className={styles.PlayerProfileInfo__Wrapper}>
        <div className={styles.PlayerProfileInfo__LeftSide}>
          {playerDetails.team && (
            <button className={styles.PlayerProfileInfo__TeamButton} onClick={handleTeamClick}>
              <Avatar
                size={AvatarSize.XS}
                username={playerDetails.team.code}
                src={playerDetails.team.smallLogoUrl}
              />
              <span className={styles.PlayerProfileInfo__TeamName}>
                {playerDetails.team.nickname}
              </span>
            </button>
          )}
          <div className={styles.PlayerProfileInfo__TeamInfo}>
            {isDesktopPlus && <div className={styles.Separator}>{ENCODED_BULLET}</div>}
            {playerDetails?.stats?.position && (
              <div className={styles.PlayerProfileInfo__Position}>
                {playerDetails?.stats?.position}
              </div>
            )}
            {playerDetails?.stats?.position && playerDetails.jersey && (
              <div className={styles.Separator}>{ENCODED_BULLET}</div>
            )}
            {playerDetails.jersey && (
              <div className={styles.PlayerProfileInfo__Jersey}>#{playerDetails.jersey}</div>
            )}
          </div>
        </div>

        <div className={styles.PlayerProfileInfo__AvatarWrapper}>
          <button className={styles.PlayerProfileInfo__Avatar} onClick={handleOpenPreview}>
            <Avatar
              src={playerDetails.mediumLogoUrl}
              username={playerDetails.firstname}
              size={AvatarSize.MEGA}
            />
          </button>
          {playerDetails.emojiUrl && (
            <div className={styles.PlayerProfileInfo__Emoji}>
              <img
                src={playerDetails.emojiUrl}
                alt={`${playerDetails.firstname}-${playerDetails.lastname}-emoji`}
              />
            </div>
          )}
        </div>

        <div className={styles.PlayerProfileInfo__Follow}>
          {isDesktopPlus && (
            <div className={playerProfileStyles.Socials}>
              <ProfileInfoSocials
                instagramUrl={getLinkValueByKey(socialLinks, LinkType.Instagram)}
                twitterUrl={getLinkValueByKey(socialLinks, LinkType.Twitter)}
                youtubeUrl={getLinkValueByKey(socialLinks, LinkType.Youtube)}
                tiktokUrl={getLinkValueByKey(socialLinks, LinkType.TikTok)}
                snapchat={getLinkValueByKey(socialLinks, LinkType.Snapchat)}
                discord={getLinkValueByKey(socialLinks, LinkType.Discord)}
              />
            </div>
          )}
          {isFollowFetched ? (
            <Button
              onClick={props.onFollowPlayer}
              theme={ButtonTheme.Primary}
              size={ButtonSize.Small}
              disabled={isFollowFetching || isFollowedPlayer}
              iconName={isFollowedPlayer ? IconFontName.Confirm : IconFontName.Add}
              iconSize={IconFontSize.ExtraSmall}
            >
              {isFollowedPlayer ? 'Following' : 'Follow'}
            </Button>
          ) : null}
        </div>
      </div>
      <h3 className={styles.PlayerProfileInfo__Name}>
        {playerDetails.firstname} {playerDetails.lastname}
      </h3>
    </div>
  );
});
