import { forwardRef, useEffect, useMemo, useRef } from 'react';
import {
  BodyScrollEvent,
  ColDef,
  GridReadyEvent,
  ModelUpdatedEvent,
  SortChangedEvent,
} from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import cn from 'classnames';
import { SortType } from 'types/sort-type.type';

import { IUserBalance } from 'stores/leaderboard/interfaces/leaderboard.interface';
import { ReputationPointsCategories } from 'stores/reputations-points/interfaces/reputation-points.interface';

import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';

import { useMainProvider } from 'hooks/use-main-provider';
import { useResponsive } from 'hooks/use-responsive';

import { BaseLazyTableGrid } from 'components/base-table/base-lazy-table';
import { RANK_COL_DEF } from 'components/base-table/tables.configs';
import {
  DEFAULT_COL_WIDTH,
  DEFAULT_PARTICIPATION_COL_DEF,
  DEFAULT_PLAYER_COL_DEF,
  DEFAULT_PREDICTIONS_COL_DEF,
  DEFAULT_TOTAL_COL_DEF,
  DEFAULT_USER_COL_DEF,
  THEME_CLASS_NAME,
  USER_DESKTOP_COL_WIDTH,
  USER_MOBILE_COL_WIDTH,
} from 'components/leaderboard/leaderboard.config';
import { LeaderboardRank } from 'components/leaderboard/leaderboard-rank/leaderboard-rank.component';

import './ag-theme-leaderboard.less';
import styles from './leaderboard.module.less';

interface ILeaderboardProps {
  onGridReady: (event: GridReadyEvent) => void;
  sortParam: ReputationPointsCategories;
  order: SortType;
  onUserClick: (username: string) => void;
  onSortChanged: (event: SortChangedEvent) => void;
  onSetSortParam: (sortParam: ReputationPointsCategories) => void;
  onBodyScroll: (e: BodyScrollEvent<IUserBalance>) => void;
  onModelUpdated: (e: ModelUpdatedEvent<IUserBalance>) => void;
  isLoading: boolean;
}

const ROWS_COUNT_TABLE_OPTION = 50;

export const Leaderboard = forwardRef<AgGridReact, ILeaderboardProps>(
  (props: ILeaderboardProps, tableRef) => {
    const { sortParam, order, onUserClick, onSetSortParam, isLoading } = props;

    const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);
    const { isNativeApp } = useMainProvider();

    const sortParamRef = useRef<ReputationPointsCategories>();
    const loadingRef = useRef<boolean>();

    // A workaround for updating sort param and loading state in the header components
    useEffect(() => {
      sortParamRef.current = sortParam;
      loadingRef.current = isLoading;
    }, [sortParam, isLoading]);

    const columnDefs: ColDef[] = useMemo(() => {
      const direction = order.toLowerCase() as 'asc' | 'desc';

      return [
        {
          ...RANK_COL_DEF,
          headerTooltip: undefined,
          valueGetter: 'node.id',
          cellRenderer: LeaderboardRank,
          cellRendererParams: {
            getSortParam: () => sortParamRef.current,
          },
        },
        {
          ...DEFAULT_USER_COL_DEF,
          width: isDesktopPlus ? USER_DESKTOP_COL_WIDTH : USER_MOBILE_COL_WIDTH,
          cellRendererParams: {
            onUserClick,
          },
        },
        {
          ...DEFAULT_TOTAL_COL_DEF,
          ...(!isDesktopPlus ? { maxWidth: DEFAULT_COL_WIDTH } : {}),
          ...(sortParam === ReputationPointsCategories.TOTAL_POINTS ? { sort: direction } : {}),
          headerComponentParams: {
            ...DEFAULT_TOTAL_COL_DEF.headerComponentParams,
            setSortParam: onSetSortParam,
            getIsLoading: () => loadingRef.current,
          },
        },
        {
          ...DEFAULT_PLAYER_COL_DEF,
          ...(!isDesktopPlus ? { maxWidth: DEFAULT_COL_WIDTH } : {}),
          ...(sortParam === ReputationPointsCategories.PLAYER_POINTS ? { sort: direction } : {}),
          headerComponentParams: {
            ...DEFAULT_PLAYER_COL_DEF.headerComponentParams,
            setSortParam: onSetSortParam,
            getIsLoading: () => loadingRef.current,
          },
        },
        {
          ...DEFAULT_PARTICIPATION_COL_DEF,
          ...(!isDesktopPlus ? { maxWidth: DEFAULT_COL_WIDTH } : {}),
          ...(sortParam === ReputationPointsCategories.PARTICIPATION_POINTS
            ? { sort: direction }
            : {}),
          headerComponentParams: {
            ...DEFAULT_PARTICIPATION_COL_DEF.headerComponentParams,
            setSortParam: onSetSortParam,
            getIsLoading: () => loadingRef.current,
          },
        },
        {
          ...DEFAULT_PREDICTIONS_COL_DEF,
          ...(!isDesktopPlus ? { maxWidth: DEFAULT_COL_WIDTH } : {}),
          ...(sortParam === ReputationPointsCategories.PREDICTION_POINTS
            ? { sort: direction }
            : {}),
          headerComponentParams: {
            ...DEFAULT_PREDICTIONS_COL_DEF.headerComponentParams,
            setSortParam: onSetSortParam,
            getIsLoading: () => loadingRef.current,
          },
        },
      ];
    }, [isDesktopPlus, onUserClick, onSetSortParam, sortParam, order]);

    const leaderboardClassNames = useMemo(() => {
      return cn(styles.Leaderboard, THEME_CLASS_NAME, {
        [styles['Leaderboard--mobileWeb']]: !isNativeApp && !isDesktopPlus,
      });
    }, [isNativeApp, isDesktopPlus]);

    return (
      <div className={leaderboardClassNames}>
        <BaseLazyTableGrid
          onGridReady={props.onGridReady}
          onModelUpdated={props.onModelUpdated}
          onBodyScroll={props.onBodyScroll}
          ref={tableRef}
          gridOptions={{
            columnDefs,
            rowHeight: isDesktopPlus ? 48 : 40,
            gridId: 'leaderboard',
            rowModelType: 'infinite',
            domLayout: 'normal',
          }}
          infiniteInitialRowCount={ROWS_COUNT_TABLE_OPTION}
          cacheOverflowSize={ROWS_COUNT_TABLE_OPTION}
          maxBlocksInCache={ROWS_COUNT_TABLE_OPTION}
          onSortChanged={props.onSortChanged}
        />
      </div>
    );
  },
);
