import { memo } from 'react';

import { IDaysStreak } from 'stores/reputations-points/interfaces/reputation-points.interface';

import {
  AchievementCard,
  AchievementType,
} from 'components/achievements/achievement-card/achievement-card.component';

import styles from './user-achievements.module.less';

interface IUserAchievementsProps {
  daysStreak: Maybe<IDaysStreak>;
}

export const UserAchievements = memo((props: IUserAchievementsProps) => {
  const { daysStreak } = props;

  return (
    <div className={styles.UserAchievements}>
      {daysStreak && (
        <AchievementCard
          type={AchievementType.Streak}
          title="Day Streak"
          currentDaysProgress={daysStreak.current}
          maxDays={daysStreak.max}
        />
      )}
    </div>
  );
});
