import { UserPublicAchievementsContainer } from 'containers/user-public-achievements/user-public-achievements.container';
import { UserPublicProfileTabsContainer } from 'containers/user-public-profile-tabs/user-public-profile-tabs.container';

import styles from './user-public-achievements.module.less';

export const UserAchievementsRoute = () => {
  return (
    <div className={styles.UserAchievements}>
      <UserPublicProfileTabsContainer />
      <UserPublicAchievementsContainer />
    </div>
  );
};
