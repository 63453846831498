import { memo, useCallback, useMemo } from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import cn from 'classnames';

import { IUser, IUserBalance } from 'stores/leaderboard/interfaces/leaderboard.interface';

import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';

import { useResponsive } from 'hooks/use-responsive';

import { Avatar, AvatarSize } from 'components/ui/avatar/avatar.component';

import styles from './leaderboard-user.module.less';

interface ILeaderboardUserProps extends ICellRendererParams<IUserBalance, IUser> {
  onUserClick: (username: string) => void;
}

export const LeaderboardUser = memo((props: ILeaderboardUserProps) => {
  const { value, onUserClick, node } = props;

  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);

  const handleUserClick = useCallback(() => {
    if (value?.username) {
      onUserClick(value.username);
    }
  }, [onUserClick, value?.username]);

  const isPinned = useMemo(() => node?.rowPinned, [node]);

  const classNames = useMemo(() => {
    return cn(styles.LeaderboardUser, {
      [styles.LeaderboardUser__pinned]: isPinned,
    });
  }, [isPinned]);

  if (!value) {
    return null;
  }

  return (
    <button className={classNames} onClick={handleUserClick}>
      <Avatar
        size={isDesktopPlus ? AvatarSize.S : AvatarSize.XS}
        username={value.username}
        src={value.avatarSrc}
      />
      <span className={styles.LeaderboardUser__Username}>
        {!isPinned ? `@${value.username}` : 'You'}
      </span>
    </button>
  );
});
