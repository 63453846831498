import { memo, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router';

import { ButtonTapsEnum } from 'services/google-analytic/enums/buttom-taps.enum';
import GoogleAnalyticService from 'services/google-analytic/google-analytic.service';

import { IFollow } from 'stores/follow/interfaces/follow.interface';
import { ITeam } from 'stores/teams/interfaces/team.interface';

import * as paths from 'routes/paths.constants';

import { BarAction } from 'components/bars/bar-action.enum';
import { BarActionType } from 'components/bars/bar-action.type';
import { SidebarCommunitiesList } from 'components/sidebars/sidebar-communities-list/sidebar-communities-list.component';
import { SidebarFollowingSectionDesktop } from 'components/sidebars/sidebar-followings-section-desktop/sidebar-following-section-desktop.component';
import { Button, ButtonSize, ButtonTheme } from 'components/ui/button/button.component';

import logo from 'assets/images/svg/logo.svg';
import rankingsDark from 'assets/images/svg/rankings-dark.svg';
import rankingsLight from 'assets/images/svg/rankings-light.svg';
import leadersDark from 'assets/images/svg/sidebar-icons/leaders-dark.svg';
import leadersLight from 'assets/images/svg/sidebar-icons/leaders-light.svg';
import scoresLight from 'assets/images/svg/sidebar-icons/scores-dark.svg';
import scoresDark from 'assets/images/svg/sidebar-icons/scores-light.svg';
import standingsDark from 'assets/images/svg/sidebar-icons/standings-dark.svg';
import standingsLight from 'assets/images/svg/sidebar-icons/standings-light.svg';
import statsDark from 'assets/images/svg/sidebar-icons/stats-dark.svg';
import statsLight from 'assets/images/svg/sidebar-icons/stats-light.svg';

import { SidebarItem, SidebarItemTheme } from '../../ui/sidebar-item/sidebar-item.component';

import styles from './sidebar-desktop.module.less';

interface ISidebarProps {
  activePath: string;
  exploreTeams: ITeam[];
  followedTeams: ITeam[];
  teamId: Maybe<number>;
  teamName?: string;
  followingPlayers: IFollow[];
  onClick: (action: BarActionType) => void;
  onTeamFollow: () => void;
  onLogoClick: () => void;
}

export const SidebarDesktop = memo((props: ISidebarProps) => {
  const { activePath, followedTeams, exploreTeams, teamName, teamId, followingPlayers, onClick } =
    props;

  const navigate = useNavigate();

  const handleScoresClick = useCallback(() => {
    if (onClick) {
      onClick({
        type: BarAction.Link,
        path: paths.HOME_SCORES,
      });
    }
  }, [onClick]);

  const handleStatsClick = useCallback(() => {
    if (onClick) {
      onClick({
        type: BarAction.Link,
        path: paths.HOME_STATS,
      });
    }
  }, [onClick]);

  const handleStandingsClick = useCallback(() => {
    if (onClick) {
      onClick({
        type: BarAction.Link,
        path: paths.HOME_STANDINGS,
      });
    }
  }, [onClick]);

  const handleLeadersClick = useCallback(() => {
    if (onClick) {
      onClick({
        type: BarAction.Link,
        path: paths.LEADERBOARD,
      });
    }
  }, [onClick]);

  const handleRankingsClick = useCallback(() => {
    GoogleAnalyticService.event({
      eventName: 'button_custom_tap',
      eventParams: {
        button_tap_type: ButtonTapsEnum.Rankings,
      },
    });

    onClick?.({ type: BarAction.Link, path: paths.PLAYERS_RANKINGS });
  }, [onClick]);

  const isHomeActive = useMemo<boolean>(() => activePath === paths.HOME_FEED, [activePath]);

  const isScoresActive = useMemo<boolean>(
    () => activePath.includes(paths.HOME_SCORES),
    [activePath],
  );

  const isStatsActive = useMemo<boolean>(() => activePath.includes(paths.HOME_STATS), [activePath]);

  const isStandingsActive = useMemo<boolean>(
    () => activePath.includes(paths.HOME_STANDINGS),
    [activePath],
  );

  const isLeadersActive = useMemo<boolean>(
    () => activePath.includes(paths.LEADERBOARD),
    [activePath],
  );

  const isRankingsActive = useMemo<boolean>(
    () => activePath.includes(paths.RANKINGS_PART),
    [activePath],
  );

  const handleEditClick = useCallback(() => {
    GoogleAnalyticService.event({
      eventName: 'button_custom_tap',
      eventParams: {
        button_tap_type: ButtonTapsEnum.ExploreEditFavorites,
        team: teamName,
      },
    });

    navigate(paths.FOLLOWING);
  }, [teamName, navigate]);

  const itemsLength = useMemo(
    () => followedTeams.length + followingPlayers.length,
    [followingPlayers, followedTeams],
  );

  return (
    <nav className={styles.SidebarDesktop}>
      <SidebarItem
        isActive={isHomeActive}
        isRoot
        imageSrc={logo}
        name="Home"
        theme={SidebarItemTheme.Vector}
        onClick={props.onLogoClick}
      />
      <ul className={styles.SidebarDesktop__List}>
        <SidebarItem
          isActive={isScoresActive}
          isSetting
          name="Scores"
          imageSrc={isScoresActive ? scoresDark : scoresLight}
          theme={SidebarItemTheme.Vector}
          onClick={handleScoresClick}
        />
        <SidebarItem
          isActive={isRankingsActive}
          name="Rankings"
          isSetting
          imageSrc={isRankingsActive ? rankingsLight : rankingsDark}
          theme={SidebarItemTheme.Vector}
          onClick={handleRankingsClick}
        />
        <SidebarItem
          isActive={isStatsActive}
          name="Stats"
          isSetting
          imageSrc={isStatsActive ? statsLight : statsDark}
          theme={SidebarItemTheme.Vector}
          onClick={handleStatsClick}
        />
        <SidebarItem
          isActive={isStandingsActive}
          name="Standings"
          isSetting
          imageSrc={isStandingsActive ? standingsLight : standingsDark}
          theme={SidebarItemTheme.Vector}
          onClick={handleStandingsClick}
        />
        <SidebarItem
          isActive={isLeadersActive}
          name="Leaders"
          isSetting
          imageSrc={isLeadersActive ? leadersLight : leadersDark}
          theme={SidebarItemTheme.Vector}
          onClick={handleLeadersClick}
        />
        <div className={styles.SidebarDesktop__Follow}>
          <Button theme={ButtonTheme.Primary} size={ButtonSize.Small} onClick={handleEditClick}>
            Follow
          </Button>
        </div>
        <div className={styles.SidebarDesktop__Communities}>
          {!!itemsLength && (
            <>
              <hr className={styles.SidebarDesktop__Separator} />
              <li className={styles.SidebarDesktop__SectionLabel}>Following</li>
              <li className={styles.SidebarDesktop__CommunitiesListItem}>
                <SidebarFollowingSectionDesktop
                  teamId={teamId}
                  activePath={activePath}
                  followedTeams={followedTeams}
                  onTeamClick={props.onClick}
                  followingPlayers={followingPlayers}
                />
              </li>
            </>
          )}
          {!!exploreTeams.length && (
            <>
              <hr className={styles.SidebarDesktop__Separator} />
              <li className={styles.SidebarDesktop__SectionLabel}>Explore</li>
              <li className={styles.SidebarDesktop__CommunitiesListItem}>
                <SidebarCommunitiesList
                  isExploreList
                  activePath={activePath}
                  teams={exploreTeams}
                  teamId={teamId}
                  onClick={props.onClick}
                  onJoinTeam={props.onTeamFollow}
                />
              </li>
            </>
          )}
        </div>
      </ul>
    </nav>
  );
});
