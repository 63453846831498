import { FC, memo, SyntheticEvent, useMemo } from 'react';
import cn from 'classnames';

import { IReputationTransaction } from 'stores/reputation-transactions/interfaces/reputation-transaction.interface';
import { ReputationPointsCategories } from 'stores/reputations-points/interfaces/reputation-points.interface';

import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';
import { formatNumberToComma } from 'helpers/format/format-number-to-comma.util';

import { useInfiniteScroll } from 'hooks/use-infinite-scroll';
import { useResponsive } from 'hooks/use-responsive';

import { Rank, RankEnum } from 'components/rank/rank.component';
import {
  ICategoryData,
  ICategoryRowItem,
} from 'components/reputation-categories/reputation-categories.component';
import { ReputationTransactionItem } from 'components/reputation-transaction-item/reputation-transaction-item.component';
import { Avatar, AvatarSize } from 'components/ui/avatar/avatar.component';
import { IconButton, IconButtonTheme } from 'components/ui/icon-button/icon-button.component';
import { IconFont, IconFontName, IconFontSize } from 'components/ui/icon-font/icon-font.component';
import { Loader } from 'components/ui/loader/loader.component';

import styles from './reputation-transactions.module.less';

interface IReputationTransactionsProps {
  loading: boolean;
  type: ReputationPointsCategories;
  reputationTransactions: Maybe<IReputationTransaction[]>;
  categoryData: ICategoryData;
  onBackClick: () => void;
  onRankClick: (
    event: SyntheticEvent,
    type: ReputationPointsCategories,
    bucketId: Maybe<string>,
  ) => void;
  currentSubCategoryData?: ICategoryRowItem;
  capitalizedType: string;
  iconName: IconFontName;
  hasMore: boolean;
  onLoadMore: () => void;
  onCloseClick: () => void;
}

const TRANSACTION_AMOUNT_BEFORE_PRE_LOAD = 5;

export const ReputationTransactions: FC<IReputationTransactionsProps> = memo(
  (props: IReputationTransactionsProps) => {
    const {
      loading,
      type,
      categoryData,
      reputationTransactions,
      currentSubCategoryData,
      capitalizedType,
      iconName,
      hasMore,
      onLoadMore,
    } = props;

    const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);

    const totalWrapperClasses = useMemo(() => {
      return cn(styles.ReputationPointsItems__TotalWrapper, {
        [styles['ReputationPointsItems__TotalWrapper--total']]:
          type === ReputationPointsCategories.TOTAL_POINTS,
      });
    }, [type]);

    const valueClasses = useMemo(() => {
      return cn(styles.ReputationPointsItems__Value, {
        [styles['ReputationPointsItems__Value--total']]:
          type === ReputationPointsCategories.TOTAL_POINTS,
        [styles['ReputationPointsItems__Value--predictionNegative']]:
          type === ReputationPointsCategories.PREDICTION_POINTS && categoryData.value < 0,
        [styles['ReputationPointsItems__Value--predictionPositive']]:
          type === ReputationPointsCategories.PREDICTION_POINTS && categoryData.value > 0,
      });
    }, [type, categoryData.value]);

    const setIntersectionObserver = useInfiniteScroll(hasMore, onLoadMore);

    return (
      <div className={styles.ReputationPointsItems}>
        <div className={styles.ReputationPointsItems__Wrapper}>
          <IconButton
            theme={IconButtonTheme.Transparent}
            iconName={IconFontName.ChevronLeft}
            onClick={props.onBackClick}
          />
          <div className={styles.ReputationPointsItems__GlobalHeader}>
            {capitalizedType} Details
          </div>
          {isDesktopPlus && (
            <IconButton
              theme={IconButtonTheme.Transparent}
              iconName={IconFontName.Close}
              onClick={props.onCloseClick}
            />
          )}
        </div>
        {currentSubCategoryData && (
          <div className={styles.ReputationPointsItems__TotalRow}>
            <div className={styles.ReputationPointsItems__ReputationHeader}>
              <div className={styles.ReputationPointsItems__CategoryWrapper}>
                <Avatar
                  src={currentSubCategoryData.titleSrc}
                  username={currentSubCategoryData.title}
                  size={AvatarSize.S}
                />
                <div className={styles.ReputationPointsItems__TotalLabel}>Total Amount</div>
              </div>
              <div className={totalWrapperClasses}>
                <IconFont name={iconName} size={IconFontSize.Small} />
                <div className={valueClasses}>
                  {formatNumberToComma(currentSubCategoryData.value)}
                </div>
              </div>
            </div>
            {currentSubCategoryData.rank && (
              <Rank
                onClick={(event: SyntheticEvent) =>
                  props.onRankClick(event, type, String(currentSubCategoryData.id))
                }
                type={RankEnum.SQUARE}
                rank={currentSubCategoryData.rank}
              />
            )}
          </div>
        )}
        {reputationTransactions &&
          reputationTransactions.map((item, index) => {
            return (
              <div
                key={item.id}
                ref={
                  reputationTransactions.length < index + TRANSACTION_AMOUNT_BEFORE_PRE_LOAD
                    ? setIntersectionObserver
                    : undefined
                }
              >
                <ReputationTransactionItem item={item} />
              </div>
            );
          })}
        {loading && <Loader isShow isLocal />}
      </div>
    );
  },
);
