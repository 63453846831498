import { getPath } from 'helpers/get-path.util';
import * as paths from 'routes/paths.constants';

import { BarAction } from 'components/bars/bar-action.enum';
import { BarActionsListType } from 'components/bars/bar-actions-list-type.enum';
import { IconFontName } from 'components/ui/icon-font/icon-font.component';

export function stringIncludesOneOf(string: string, valuesArray: string[]) {
  return valuesArray.some((value) => string.includes(value));
}

export function getSubMenuItems(
  isGlobalMenu: boolean,
  playerSlug?: string,
  teamId?: string,
  username?: string,
  isAthlete?: boolean,
) {
  if (isGlobalMenu) {
    return [
      {
        name: 'Ranking',
        action: {
          type: BarAction.Link,
          path: paths.PLAYERS_RANKINGS,
          payload: BarActionsListType.Rankings,
        },
        icon: { default: IconFontName.Rankings, active: IconFontName.RankingsFilled },
      },
      {
        name: 'Stats',
        action: {
          type: BarAction.Link,
          path: paths.HOME_STATS_PLAYERS,
          payload: BarActionsListType.Stats,
        },
        icon: { default: IconFontName.Rating, active: IconFontName.RatingFilled },
      },
      {
        name: 'Standings',
        action: {
          type: BarAction.Link,
          path: paths.HOME_STANDINGS,
          payload: BarActionsListType.Standings,
        },
        icon: { default: IconFontName.Winner, active: IconFontName.WinnerFilled },
      },
    ];
  }

  if (teamId) {
    return [
      {
        name: 'Feed',
        action: {
          type: BarAction.Link,
          path: getPath(paths.TEAM_FEED, {
            [paths.TEAM_ID_PARAM]: teamId,
          }),
          payload: BarActionsListType.Feed,
        },
        icon: { default: IconFontName.Feedback, active: IconFontName.Feedback },
      },
      {
        name: 'Stats',
        action: {
          type: BarAction.Link,
          path: getPath(paths.TEAM_SCORES, {
            [paths.TEAM_ID_PARAM]: teamId,
          }),
          payload: BarActionsListType.TeamScores,
        },
        icon: { default: IconFontName.Rating, active: IconFontName.RatingFilled },
      },
      {
        name: 'Profile',
        action: {
          type: BarAction.Link,
          path: getPath(paths.TEAM_PROFILE, {
            [paths.TEAM_ID_PARAM]: teamId,
          }),
          payload: BarActionsListType.Profile,
        },
        icon: { default: IconFontName.Profile, active: IconFontName.ProfileFilled },
      },
    ];
  }

  if (playerSlug) {
    return [
      {
        name: 'Feed',
        action: {
          type: BarAction.Link,
          path: getPath(paths.PLAYER_PROFILE_FEED, {
            [paths.PLAYER_SLUG_PARAM]: playerSlug,
          }),
          payload: BarActionsListType.Feed,
        },
        icon: { default: IconFontName.Feedback, active: IconFontName.Feedback },
      },
      {
        name: 'Stats',
        action: {
          type: BarAction.Link,
          path: getPath(paths.PLAYER_PROFILE_GAMES, {
            [paths.PLAYER_SLUG_PARAM]: playerSlug,
          }),
          payload: BarActionsListType.PlayerStats,
        },
        icon: { default: IconFontName.Rating, active: IconFontName.RatingFilled },
      },
      {
        name: 'Profile',
        action: {
          type: BarAction.Link,
          path: getPath(paths.PLAYER_PROFILE_OVERVIEW, {
            [paths.PLAYER_SLUG_PARAM]: playerSlug,
          }),
          payload: BarActionsListType.Profile,
        },
        icon: { default: IconFontName.Profile, active: IconFontName.ProfileFilled },
      },
    ];
  }

  if (username) {
    const tabs = [
      {
        name: 'Feed',
        action: {
          type: BarAction.Link,
          path: getPath(paths.USER_PUBLIC_FEED, {
            [paths.USERNAME_PARAM]: username,
          }),
          payload: BarActionsListType.Feed,
        },
        icon: { default: IconFontName.Feedback, active: IconFontName.Feedback2 },
      },
      {
        name: 'Stats',
        action: {
          type: BarAction.Link,
          path: getPath(paths.USER_PUBLIC_STATS, {
            [paths.USERNAME_PARAM]: username,
          }),
          payload: BarActionsListType.PlayerStats,
        },
        icon: { default: IconFontName.Rating, active: IconFontName.RatingFilled },
      },
      {
        name: 'Profile',
        action: {
          type: BarAction.Link,
          path: getPath(paths.USER_PUBLIC_PROFILE, {
            [paths.USERNAME_PARAM]: username,
          }),
          payload: BarActionsListType.Profile,
        },
        icon: { default: IconFontName.Profile, active: IconFontName.ProfileFilled },
      },
    ];

    if (isAthlete) {
      tabs.splice(1, 1);
    }

    return tabs;
  }

  return [];
}
