import { memo, useMemo } from 'react';
import cn from 'classnames';

import {
  AchievementProgressBar,
  AchievementProgressBarTheme,
} from 'components/achievements/achievement-progress-bar/achievement-progress-bar.component';
import { DaysStreakAchievementBadge } from 'components/achievements/days-streak-achievement-badge/days-streak-achievement-badge.component';

import styles from './achievement-card.module.less';

export enum AchievementType {
  Streak = 'streak',
  Platinum = 'platinum',
  PlatinumProgress = 'platinum-progress',
  Gold = 'gold',
  GoldProgress = 'gold-progress',
  Silver = 'silver',
  SilverProgress = 'silver-progress',
  Bronze = 'bronze',
  BronzeProgress = 'bronze-progress',
}

export interface IAchievementCardProps {
  type: AchievementType;
  achievementProgressTheme?: AchievementProgressBarTheme;
  progress?: number;
  currentDaysProgress?: number;
  maxDays?: number;
  badgeUrl?: string;
  title: string;
  description?: string;
  totalPoints?: number;
  earnedPoints?: number;
}

export const AchievementCard = memo((props: IAchievementCardProps) => {
  const {
    type,
    achievementProgressTheme,
    progress,
    currentDaysProgress,
    maxDays,
    badgeUrl,
    title,
    description,
    earnedPoints,
    totalPoints,
  } = props;

  const classNames = useMemo(() => {
    return cn(styles.AchievementCard, {
      [styles.AchievementCard__streak]: type === AchievementType.Streak,
      [styles.AchievementCard__platinum]: type === AchievementType.Platinum,
      [styles.AchievementCard__platinumProgress]: type === AchievementType.PlatinumProgress,
      [styles.AchievementCard__gold]: type === AchievementType.Gold,
      [styles.AchievementCard__goldProgress]: type === AchievementType.GoldProgress,
      [styles.AchievementCard__silver]: type === AchievementType.Silver,
      [styles.AchievementCard__silverProgress]: type === AchievementType.SilverProgress,
      [styles.AchievementCard__bronze]: type === AchievementType.Bronze,
      [styles.AchievementCard__bronzeProgress]: type === AchievementType.BronzeProgress,
    });
  }, [type]);

  return (
    <div className={classNames}>
      <div className={styles.AchievementBackground} />
      {type === AchievementType.Streak ? (
        <div className={styles.AchievementCard__Streak}>
          <DaysStreakAchievementBadge value={maxDays || 0} showMax />
        </div>
      ) : (
        <div className={styles.AchievementCard__Badge}>
          <img src={badgeUrl} alt={title} />
        </div>
      )}
      <div className={styles.AchievementContent}>
        <div className={styles.AchievementContent__Info}>
          <p className={styles.AchievementContent__Title}>{title}</p>
          {description && <p className={styles.AchievementContent__Description}>{description}</p>}
          {totalPoints && earnedPoints && (
            <span className={styles.AchievementContent__Points}>
              {earnedPoints} / {totalPoints}
            </span>
          )}
        </div>
        <div className={styles.AchievementContent__Progress}>
          {type === AchievementType.Streak ? (
            <div className={styles.AchievementContent__Progress__Days}>
              <h4>{currentDaysProgress}</h4>
              <span>days</span>
            </div>
          ) : (
            <AchievementProgressBar
              theme={achievementProgressTheme ?? AchievementProgressBarTheme.Default}
              fill={progress}
            />
          )}
        </div>
      </div>
    </div>
  );
});
