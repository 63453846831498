import { FC, memo, SyntheticEvent, useCallback, useMemo } from 'react';
import cn from 'classnames';

import { ReputationPointsCategories } from 'stores/reputations-points/interfaces/reputation-points.interface';

import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';
import { formatNumberToComma } from 'helpers/format/format-number-to-comma.util';

import { useInfiniteScroll } from 'hooks/use-infinite-scroll';
import { useResponsive } from 'hooks/use-responsive';

import { Rank, RankEnum } from 'components/rank/rank.component';
import { Avatar, AvatarSize } from 'components/ui/avatar/avatar.component';
import { IconButton, IconButtonTheme } from 'components/ui/icon-button/icon-button.component';
import { IconFont, IconFontName, IconFontSize } from 'components/ui/icon-font/icon-font.component';
import { Loader } from 'components/ui/loader/loader.component';

import styles from './reputation-categories.module.less';

const CATEGORIES_AMOUNT_BEFORE_PRE_LOAD = 5;

export interface ICategoryRowItem {
  id: number;
  titleSrc: string;
  title: string;
  rank?: number;
  value: number;
}

export interface ICategoryData {
  rank?: number;
  value: number;
}

interface IReputationCategoriesProps {
  isShowTotalRow: boolean;
  loading?: boolean;
  type: ReputationPointsCategories;
  items: Maybe<ICategoryRowItem[]>;
  categoryData: ICategoryData;
  onRowClick: (id: number) => void;
  onRankClick: (
    event: SyntheticEvent,
    type: ReputationPointsCategories,
    bucketId: Maybe<string>,
  ) => void;
  capitalizedType?: string;
  iconName: IconFontName;
  hasMore?: boolean;
  onLoadMore?: () => void;
  onCloseClick?: () => void;
}

export const ReputationCategories: FC<IReputationCategoriesProps> = memo(
  (props: IReputationCategoriesProps) => {
    const {
      isShowTotalRow,
      loading,
      type,
      items,
      onRowClick,
      categoryData,
      capitalizedType,
      iconName,
      hasMore,
      onLoadMore,
    } = props;

    const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);

    const itemWrapperClasses = useMemo(() => {
      return cn(styles.ReputationPointsCategories, {
        [styles['ReputationPointsCategories--without-padding']]: !isShowTotalRow,
      });
    }, [isShowTotalRow]);

    const totalWrapperClasses = useMemo(() => {
      return cn(styles.ReputationPointsCategories__TotalWrapper, {
        [styles['ReputationPointsCategories__TotalWrapper--total']]:
          type === ReputationPointsCategories.TOTAL_POINTS,
      });
    }, [type]);

    const headerClasses = useMemo(() => {
      return cn(styles.ReputationPointsCategories__GlobalHeader, {
        [styles['ReputationPointsCategories__GlobalHeader--with-close-button']]:
          props.onCloseClick && isDesktopPlus,
      });
    }, [props.onCloseClick, isDesktopPlus]);

    const valueClasses = useMemo(() => {
      return cn(styles.ReputationPointsCategories__Value, {
        [styles['ReputationPointsCategories__Value--total']]:
          type === ReputationPointsCategories.TOTAL_POINTS,
        [styles['ReputationPointsCategories__Value--predictionNegative']]:
          type === ReputationPointsCategories.PREDICTION_POINTS && categoryData.value < 0,
        [styles['ReputationPointsCategories__Value--predictionPositive']]:
          type === ReputationPointsCategories.PREDICTION_POINTS && categoryData.value > 0,
      });
    }, [type, categoryData.value]);

    const handleLoadMore = useCallback(() => {
      onLoadMore?.();
    }, [onLoadMore]);

    const setIntersectionObserver = useInfiniteScroll(hasMore || false, handleLoadMore);

    return (
      <div className={itemWrapperClasses}>
        {isShowTotalRow && (
          <div className={styles.ReputationPointsCategories__Wrapper}>
            <div className={headerClasses}>{capitalizedType}</div>
            {isDesktopPlus && props.onCloseClick && (
              <IconButton
                theme={IconButtonTheme.Transparent}
                iconName={IconFontName.Close}
                onClick={props.onCloseClick}
              />
            )}
          </div>
        )}
        <div className={styles.ReputationPointsCategories__TotalRow}>
          <div className={styles.ReputationPointsCategories__ReputationHeader}>
            <div className={styles.ReputationPointsCategories__TotalLabel}>Total Amount</div>
            <div className={totalWrapperClasses}>
              <IconFont name={iconName} size={IconFontSize.Small} />
              <div className={valueClasses}>{formatNumberToComma(categoryData.value)}</div>
            </div>
          </div>
          {!!categoryData.rank && (
            <Rank
              onClick={(event: SyntheticEvent) => props.onRankClick(event, type, null)}
              type={RankEnum.SQUARE}
              rank={categoryData.rank}
            />
          )}
        </div>
        {items?.map(({ id, titleSrc, title, rank, value }, index) => {
          return (
            <div
              role="button"
              tabIndex={0}
              key={id}
              onClick={() => onRowClick(id)}
              onKeyDown={() => onRowClick(id)}
              ref={
                items.length < index + CATEGORIES_AMOUNT_BEFORE_PRE_LOAD
                  ? setIntersectionObserver
                  : undefined
              }
              className={cn(styles.ReputationPointsCategories__CategoryRow, {
                [styles['ReputationPointsCategories__CategoryRow--no-rank']]: !rank,
              })}
            >
              <div className={styles.ReputationPointsCategories__Info}>
                <div className={styles.ReputationPointsCategories__CategoryLabel}>
                  <Avatar src={titleSrc} username={title} size={AvatarSize.S} />
                  <div>{title}</div>
                </div>
                <div className={styles.ReputationPointsCategories__CategoryPoints}>
                  <IconFont name={IconFontName.Star} size={IconFontSize.Small} />
                  <div>{formatNumberToComma(value)}</div>
                </div>
              </div>
              {!!rank && (
                <Rank
                  onClick={(event: SyntheticEvent) => props.onRankClick(event, type, String(id))}
                  type={RankEnum.SQUARE}
                  rank={rank}
                />
              )}
            </div>
          );
        })}
        {loading && <Loader isShow isLocal />}
      </div>
    );
  },
);
