import { FC, memo, useMemo } from 'react';
import cn from 'classnames';

import { IReputationTransaction } from 'stores/reputation-transactions/interfaces/reputation-transaction.interface';
import {
  TransactionActionsType,
  TransactionOwnersType,
} from 'stores/reputations-points/interfaces/reputation-points.interface';

import { IconFont, IconFontName, IconFontSize } from 'components/ui/icon-font/icon-font.component';

import styles from './reputation-transaction-item.module.less';

interface IReputationTransactionItemProps {
  item: IReputationTransaction;
}

export const ReputationTransactionItem: FC<IReputationTransactionItemProps> = memo(
  (props: IReputationTransactionItemProps) => {
    const { item } = props;

    const pointsClasses = useMemo(() => {
      return cn(styles.ReputationTransactionItem__Points, {
        [styles['ReputationTransactionItem__Points--win']]:
          item.action === TransactionActionsType.WIN_PREDICTION,
        [styles['ReputationTransactionItem__Points--loss']]:
          item.action === TransactionActionsType.LOSS_PREDICTION,
      });
    }, [item.action]);

    const iconClasses = useMemo(() => {
      return cn(styles.ReputationTransactionItem__Icon, {
        [styles['ReputationTransactionItem__Icon--win']]:
          item.action === TransactionActionsType.WIN_PREDICTION,
        [styles['ReputationTransactionItem__Icon--loss']]:
          item.action === TransactionActionsType.LOSS_PREDICTION,
        [styles['ReputationTransactionItem__Icon--athlete']]:
          item.owner === TransactionOwnersType.ATHLETE,
      });
    }, [item.action, item.owner]);

    const iconName = useMemo(() => {
      if (
        item.action === TransactionActionsType.LIKED &&
        item.owner === TransactionOwnersType.ATHLETE
      ) {
        return IconFontName.LikeFiled;
      }

      if (item.action === TransactionActionsType.LIKED) {
        return IconFontName.Like;
      }

      if (
        item.action === TransactionActionsType.COMMENTED &&
        item.owner === TransactionOwnersType.ATHLETE
      ) {
        return IconFontName.ChatFilled;
      }

      if (item.action === TransactionActionsType.COMMENTED) {
        return IconFontName.Chat;
      }

      if (item.action === TransactionActionsType.LOSS_PREDICTION) {
        return IconFontName.Downvote;
      }

      if (item.action === TransactionActionsType.WIN_PREDICTION) {
        return IconFontName.Upvote;
      }

      if (item.action === TransactionActionsType.PARTICIPATED) {
        return IconFontName.MediaFiles;
      }

      if (item.action === TransactionActionsType.CREATED) {
        return IconFontName.Post;
      }

      return null;
    }, [item]);

    return (
      <a className={styles.ReputationTransactionItem} href={item.link}>
        <div className={styles.ReputationTransactionItem__Description}>
          <div className={styles.ReputationTransactionItem__Title}>{item.title}</div>
          <div className={styles.ReputationTransactionItem__Date}>{item.date}</div>
        </div>
        <div className={styles.ReputationTransactionItem__Values}>
          <div className={pointsClasses}>{item.points}</div>
          {iconName && (
            <div className={iconClasses}>
              <IconFont name={iconName} size={IconFontSize.Small} />
            </div>
          )}
        </div>
      </a>
    );
  },
);
