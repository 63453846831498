import { IReputationTransactionResponse } from 'services/reputation-points/interfaces/reputation-transaction-response.interface';

import { IReputationTransaction } from 'stores/reputation-transactions/interfaces/reputation-transaction.interface';

import { formatPastDate } from 'helpers/format/format-past-date.util';

export function reputationTransactionsAdapter(
  response: IReputationTransactionResponse,
): IReputationTransaction {
  const { id, title, reward, performer, action, link, created_at: createdAt } = response;

  return {
    id,
    title,
    points: reward,
    owner: performer,
    action,
    date: formatPastDate(createdAt, 'relativeLong'),
    link,
  };
}
